import { useState, useEffect } from "react";
import { pdf, Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import styled from "styled-components";
import { Dialog } from '@mui/material';
import { FiX,FiDownload } from "react-icons/fi";
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { saveAs } from "file-saver";

import { H2, H3, H4, H5, H6, P1, P2, P3 } from "../components/Typography";
import { HBox, VBox } from "../components/Containers";
import { Button, IconButton } from "../components/Buttons";
import colors from "../config/colors";
import responsive from "../config/responsive";
import { getTime } from "../utils";
import logoMain from '../assets/images/logo_main.png';
import phone from '../assets/images/phone.png';
import mail from '../assets/images/mail.png';
import internet from '../assets/images/internet.png';
import square from '../assets/images/square.png';
import location from '../assets/images/location.png';
import kalpurush from '../assets/fonts/kalpurush.ttf';
import robotoLight from '../assets/fonts/Roboto-Light.ttf';
import robotoRegular from '../assets/fonts/Roboto-Regular.ttf';
import robotoBold from '../assets/fonts/Roboto-Bold.ttf';
import robotoItalic from '../assets/fonts/Roboto-Italic.ttf';


Font.register({ family: 'Kalpurush', src: kalpurush });
Font.register({ family: 'Roboto-Light', src: robotoLight });
Font.register({ family: 'Roboto-Regular', src: robotoRegular });
Font.register({ family: 'Roboto-Bold', src: robotoBold });
Font.register({ family: 'Roboto-Italic', src: robotoItalic });

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

// Colors
const blue = '#119AC0';
const red = '#C75738';
const green = '#209F5C';
const lightGreen = '#d3f6e3';
const lightGrey = '#F2F2F2';
const darkGrey = '#5C5C5C';

// Fonts
const fontInput = 'Roboto-Light';
const fontTitle = 'Roboto-Bold';
const fontText = 'Roboto-Light';

const regularFontSize = 11;

const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      padding: '20px 40px 40px 40px'
    },
    heading: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '0.25px solid black',
        padding: 8,
    },
    subHeading: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottom: '0.25px solid black',
        padding: 8,
    },
    logo: {
        // backgroundColor: lightGrey,
        // padding: 4,
        alignItems: 'center',
        // borderRadius: 8,
        // boxShadow: '0px 1px 0px 0px rgba(0,0,0,0.25)',
    },
    image: {
        height: 100,
        width: 260
    },
});

const getDaysStr = (schedule) => {
    let days = [];
    if (schedule?.saturday) days.push('Sat');
    if (schedule?.sunday) days.push('Sun');
    if (schedule?.monday) days.push('Mon');
    if (schedule?.tuesday) days.push('Tue');
    if (schedule?.wednesday) days.push('Wed');
    if (schedule?.thursday) days.push('Thu');
    if (schedule?.friday) days.push('Fri');

    return days.join(', ');
}

const Heading = ({  }) => {
    return (
        <View>
            <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '50%', flexDirection: 'row', justifyContent: 'center' }}>
                    <Image
                        src={logoMain}
                        style={styles.image}
                    />
                </View>
                <View style={{ width: '50%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <View>
                        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 3 }}>
                            <View style={{ width: 24, alignItems: 'center' }}>
                                <Image src={phone} style={{ height: 14, width: 14 }} />
                            </View>
                            <Text style={{ fontSize: 12, fontFamily: fontText }}>+880 1321 119 391</Text>
                        </View>
                        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 3 }}>
                            <View style={{ width: 24, alignItems: 'center' }}>
                                <Image src={mail} style={{ height: 10, width: 14 }} />
                            </View>
                            <Text style={{ fontSize: 12, fontFamily: fontText }}>info@nextgenmyhealth.com</Text>
                        </View>
                        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 3 }}>
                            <View style={{ width: 24, alignItems: 'center' }}>
                                <Image src={internet} style={{ height: 14, width: 14 }} />
                            </View>
                            <Text style={{ fontSize: 12, fontFamily: fontText }}>www.nextgenmyhealth.com</Text>
                        </View>
                        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 3 }}>
                            <View style={{ width: 24, alignItems: 'center' }}>
                                <Image src={location} style={{ height: 14, width: 12 }} />
                            </View>
                            <Text style={{ fontSize: 12, fontFamily: fontText }}>Dhaka, Bangladesh</Text>
                        </View>
                    </View>
                </View>
            </View>
            <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'center', marginTop: 8 }}>
                <View style={{ padding: '4px 16px', backgroundColor: lightGreen }}>
                    <Text style={{ fontSize: 18, fontFamily: 'Courier', color: green }}>DOCTOR'S CONSENT FORM</Text>
                </View>
            </View>
        </View>
    )
}

const Form = ({ doctor }) => {
    const blankStr = '';

    return (
        <View style={{ marginTop: 24, padding: 16, border: `1px solid grey` }}>
            <DoubleCol
                label1='NAME'
                text1={doctor.full_name}
                label2='PHONE'
                text2={doctor.phone}
                marginTop={0}
            />
            <DoubleCol
                label1='নাম (বাংলা)'
                text1={doctor.full_name_bangla}
                label2='EMAIL'
                text2={doctor.email ? doctor.email : blankStr}
                label1Font='Kalpurush'
                text1Font="Kalpurush"
            />
            
            <DoubleCol
                label1='BMDC REG. NUMBER'
                text1={`${doctor.doctor_type==='MBBS' ? 'A-' : ''}${doctor.bmdc}`}
                label2='EXPERIENCE'
                text2={doctor.experience}
            />
            <SingleCol
                label='ACADEMIC QUALIFICATION'
                text={doctor.qualification}
            />
            <SingleCol
                label='POST-GRAD TRAININGS'
                text={doctor.trainings}
            />
            <SingleCol
                label='SPECIALTIES'
                text={doctor.specialty}
            />
            
            {doctor.affiliations?.length ? 
                <>
                <Text style={{ fontSize: regularFontSize, fontFamily: fontTitle, marginRight: 4, marginTop: 12, color: darkGrey }}>AFFILIATIONS</Text>
                {doctor.affiliations?.map((affiliation, index) => (
                    <BulletItem text={`${affiliation.designation}, ${affiliation.institution}`} />
                ))}
                </>
                :
                null
            }
            
            {doctor.chambers?.length ?
                <>
                    <Text style={{ fontSize: regularFontSize, fontFamily: fontTitle, marginRight: 4, marginTop: 12, color: darkGrey }}>CHAMBERS</Text>
                    {doctor.chambers?.map((chamber, index) => (
                        <BulletItem text={chamber.address} />
                    ))}
                </>
                :
                null
            }
            
            {doctor.appointment_schedules?.length ?
                <>
                <Text style={{ fontSize: regularFontSize, fontFamily: fontTitle, marginRight: 4, marginTop: 12, color: darkGrey }}>CONSULTATION TIME</Text>
                {doctor.appointment_schedules?.map((appointment_schedule, index) => (
                    <BulletItem text={`From ${getTime(appointment_schedule.schedule.startTime)} to ${getTime(appointment_schedule.schedule.endTime)} on ${getDaysStr(appointment_schedule.schedule)}`} />
                ))}
                </>
                :
                null
            }

            <View style={{ flexDirection: 'row', marginTop: 12 }}>
                <View style={{ flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                    <Text style={{ fontSize: regularFontSize, fontFamily: fontTitle, marginRight: 4, marginTop: 0, color: darkGrey }}>CONSULTATION FEES :</Text>
                    <Text style={{ fontSize: regularFontSize, fontFamily: fontInput }}>
                        (Regular <Text style={{ fontSize: regularFontSize, fontFamily: 'Kalpurush' }}>৳</Text>{doctor.fee}) (Follow up <Text style={{ fontSize: 10, fontFamily: 'Kalpurush' }}>৳</Text>{doctor.followup_fee})
                    </Text>
                </View>
            </View>
            <SingleCol
                label='FOLLOW UP DURATION'
                text={`${doctor.followup_duration} days`}
            />
            
            <Text style={{ fontSize: regularFontSize, fontFamily: fontTitle, marginRight: 4, marginTop: 12, color: darkGrey }}>BANK INFORMATION</Text>
            <DoubleCol
                label1='A/C NAME'
                text1={doctor.account_name ? doctor.account_name : blankStr}
                label2='A/C NUMBER'
                text2={doctor.account_number ? doctor.account_number : blankStr}
            />
            <DoubleCol
                label1='NAME OF BANK'
                text1={doctor.bank_name ? doctor.bank_name : blankStr}
                label2='NAME OF BRANCH'
                text2={doctor.branch_name ? doctor.branch_name : blankStr}
            />
            
            <Text style={{ fontSize: 11, fontFamily: fontTitle, marginRight: 4, marginTop: 12, color: darkGrey }}>MOBILE BANKING</Text>
            <DoubleCol
                label1='OPERATOR'
                text1={doctor.mobile_banking_operator ? doctor.mobile_banking_operator : blankStr}
                label2='PHONE NUMBER'
                text2={doctor.mobile_banking_number ? doctor.mobile_banking_number : blankStr}
            />

            <Signature />
        </View>
    )
}

const DoubleCol = ({ label1, text1, label2, text2, marginTop=4,
                     label1Font=fontTitle, text1Font=fontInput,
                     label2Font=fontTitle, text2Font=fontInput }) => {
    return (
        <View style={{ flexDirection: 'row', marginTop: marginTop }}>
            <View style={{ flexDirection: 'row', width: '50%' }}>
                <Text style={{ fontSize: regularFontSize, fontFamily: label1Font, marginRight: 4, marginTop: 0, color: darkGrey }}>{label1} :</Text>
                <Text style={{ fontSize: regularFontSize, fontFamily: text1Font, textAlign: 'justify', paddingRight: 8, flex: 1 }}>{text1}</Text>
            </View>
            <View style={{ flexDirection: 'row', width: '50%' }}>
                <Text style={{ fontSize: regularFontSize, fontFamily: label2Font, marginRight: 4, marginTop: 0, color: darkGrey }}>{label2} :</Text>
                <Text style={{ fontSize: regularFontSize, fontFamily: text2Font, textAlign: 'justify',flex: 1 }}>{text2}</Text>
            </View>
        </View>
    )
}

const SingleCol = ({ label, text, marginTop=4,
                     labelFont=fontTitle, textFont=fontInput }) => {
    return (
        <View style={{ flexDirection: 'row', marginTop: marginTop, width: '100%' }}>
            <Text style={{ fontSize: regularFontSize, fontFamily: labelFont, marginRight: 4, marginTop: 0, color: darkGrey }}>{label} :</Text>
            <Text style={{ fontSize: regularFontSize, fontFamily: textFont, textAlign: 'justify', flex: 1 }}>{text}</Text>
        </View>
    )
}

const BulletItem = ({ text, textFont=fontInput, marginTop=4 }) => {
    return (
        <View style={{ flexDirection: 'row', marginTop: marginTop, width: '100%' }}>
            <Image src={square} style={{ height: 6, width: 6, margin: '2px 6px 0px 8px' }} />
            <Text style={{ fontSize: regularFontSize, fontFamily: textFont, textAlign: 'justify', flex: 1 }}>{text}</Text>
        </View>
    )
}

const TermsAndConditions = () => {
    return (
        <View>
            <View style={{ flexDirection: 'row', justifyContent: 'center', marginBottom: 8, marginTop: 16 }}>
                <Text style={{ fontSize: 14, fontFamily: fontTitle }}>Terms and Conditions</Text>
            </View>
            <Paragraph
                title='Licensing and Credentials'
                text='MBBS/BDS degree and valid BMDC registration number and credentials is required to practice medicine and treatment.'
            />
            <Paragraph
                title='Patient Privacy'
                text='Respected doctors will ensure that all patient information and data is kept confidential and secure. Doctors will follow all necessary security protocols to prevent unauthorized access to patient information.'
            />
            <Paragraph
                title='Compliance with Laws and Regulations'
                text='Honorable doctors are requested comply with all applicable laws and regulations related to medicine practice, including patient privacy, informed consent, and medical recordkeeping.'
            />
            <Paragraph
                title='Doctor’s Fees'
                text='Respected Doctors are requested to provide their fees equal to/ less than private practice fees.'
            />
            <Paragraph
                title='Service Charge'
                text='NextGen MyHealth VCP respectfully requests permission to deducted 10% as service charge over consultation fees from honorable doctors.'
            />
            <Paragraph
                title='Standard of Care'
                text='Respected doctors will provide the same standard of care that they would provide in an inperson visit. They will conduct a thorough examination, diagnose the patient’s condition, and recommend appropriate treatment.'
            />
            <Paragraph
                title='Referral to In-Person Care'
                text='If the doctor determines that the patient’s condition requires in-person care, they will refer the patient to a local healthcare provider.'
            />
            <Paragraph
                title='Technical Requirements'
                text='Respected doctors will ensure that they have the necessary technical requirements to provide telemedicine services, including a reliable internet connection, a secure video conferencing platform, and appropriate medical equipment.'
            />
            <Paragraph
                title='Availability'
                text='Honorable doctors will specify their availability for telemedicine appointments and ensure that they are accessible to patients during scheduled appointment times. In case of emergency and unable to attend a consultation, requested to notify the service provider.'
            />
            <Paragraph
                title='Use of Doctor Information'
                text='NextGen MyHealth VCP respectfully requests permission to feature doctors’ photographs and professional information, excluding sensitive personal details such as identification numbers and banking information, on its website, social media platforms, and printed materials. These resources will be utilized for promotional, marketing, awareness, and other related purposes. By granting permission, doctors kindly acknowledge their consent to this usage and express their understanding. Additionally, NextGen MyHealth VCP remains steadfastly committed to providing unwavering cooperation and support to any doctor who wishes to promote their own professional services, ensuring a mutually beneficial partnership.'
            />

            <Text style={{ fontSize: 11, fontFamily: 'Roboto-Regular', textAlign: 'justify', marginTop: 40 }}>I, hereby acknowledge and agree to the terms and conditions. I have read and understand all provisions of the agreement and agree to abide by them in full. </Text>
            <Signature />
        </View>
    )
}

const Paragraph = ({ title, text }) => {
    return (
        <Text style={{ fontSize: regularFontSize, fontFamily: fontText, textAlign: 'justify', marginTop: 12 }}>
            <Text style={{ fontSize: regularFontSize, fontFamily: fontTitle, color: darkGrey }}>
                {title} :
            </Text>
            {' '+text}
        </Text>
    )
}

const Signature = () => {
    return (
        <View style={{ flexDirection: 'row', width: '100%', marginTop: 80 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'flex-start', width: '50%' }}>
                <View style={{ alignItems: 'center', marginLeft: 32 }}>
                    <Text style={{ fontSize: 10, fontFamily: fontTitle }}>
                        Signature, Seal & Date
                    </Text>
                    <Text style={{ fontSize: 11, fontFamily: fontText }}>
                        Doctor
                    </Text>
                </View>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', width: '50%' }}>
                <View style={{ alignItems: 'center', marginRight: 32 }}>
                    <Text style={{ fontSize: 10, fontFamily: fontTitle }}>
                        Signature, Seal & Date
                    </Text>
                    <Text style={{ fontSize: 11, fontFamily: fontText }}>
                        NextGen MyHealth VCP Representative
                    </Text>
                </View>
            </View>
        </View>
    )
}

const DoctorConsentFormDocument = ({ doctor }) => {
    
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Heading />
                <Form doctor={doctor} />
            </Page>
            <Page size="A4" style={styles.page}>
                <TermsAndConditions />
            </Page>
        </Document>
    )
}

const DoctorConsentFormPDF = ({ open, setOpen, doctorId }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [doctor, setDoctor] = useState({
        
    });

    const [isMobile, setIsMobile] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {
        if (open) getData();
    }, [open]);

    const getData = async () => {
        setIsLoading(true);
        enqueueSnackbar("Loading...", { persist: true });
        await axios({
            method: 'GET',
            url: `moderator/doctor-detail/`,
            params: {
                doctor_id: doctorId
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            closeSnackbar();
            if (response.status === 200) {
                setDoctor(response.data);
            } else {
                console.log('DOCTOR DETAILS GET FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            closeSnackbar();
            console.log('DOCTOR DETAILS GET ERROR', error);
        })
    }

    // const saveFile = () => {
    //     enqueueSnackbar('Preparing for download. Please wait a moment.', { persist: true });
    //     pdf(<DoctorConsentFormDocument />)
    //       .toBlob()
    //       .then((blob) => {saveAs(blob, `${doctor?.full_name}_${doctor?.id}_Consent_Form.pdf`); closeSnackbar()});
    // }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") 
            return;
        
        setOpen(false);
    }

    return (
        <Dialog fullScreen scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="mt-1 mb-1">
                <HBox align="center" style={{ width: '33%' }}>
                    <H3 className='ml-2'>Consent Form</H3>
                    <P1 className="ml-0_5">of <span style={{ color: colors.red }}>{doctor.full_name}</span></P1>
                </HBox>
                <HBox justify='center' style={{ width: '33%' }}>
                    {/* <Button color='' size='sm' onClick={saveFile}>
                        <FiDownload className="mr-0_5" />
                        Download
                    </Button> */}
                    <PDFDownloadLink
                        document={
                            <DoctorConsentFormDocument
                                doctor={doctor}
                            />}
                        fileName={`${doctor.full_name}_${doctor.id}_Consent_Form.pdf`}
                        style={{ alignSelf: 'center', justifySelf: 'center', textDecoration: 'none' }}
                    >
                        {({ loading }) =>
                            loading ? "Loading consent form..."
                                :
                                <Button color='' size='sm'><FiDownload className="mr-0_5" />Download</Button>
                        }
                    </PDFDownloadLink>
                </HBox>
                <HBox justify='flex-end' style={{ width: '33%' }}>
                    <IconButton
                        className="mr-2"
                        onClick={handleClose}
                    >
                        <FiX />
                    </IconButton>
                </HBox>
            </HBox>
            <Line />
            <VBox>
                {isMobile ?
                    <HBox style={{ alignSelf: 'center', justifySelf: 'center', height: '90vh' }}>
                        <PDFDownloadLink
                            document={
                                <DoctorConsentFormDocument
                                    doctor={doctor}
                                />}
                            fileName={`${doctor?.full_name}_${doctor?.id}_Consent_Form.pdf`}
                            style={{ alignSelf: 'center', justifySelf: 'center', textDecoration: 'none' }}
                        >
                            {({ loading }) =>
                                loading ? "Loading consent form..."
                                    :
                                    <Button color='first'>Download Consent Form</Button>
                            }
                        </PDFDownloadLink>
                    </HBox>
                    :
                    <PDFViewer showToolbar={false} style={{ height: 'calc(100vh - 47px)' }}>
                        <DoctorConsentFormDocument
                            doctor={doctor}
                        />
                    </PDFViewer>
                }
            </VBox>
        </Dialog>
    )
}

export default DoctorConsentFormPDF;