import { useState, useEffect } from 'react';
import styled from "styled-components";
import { IoMdAddCircle } from 'react-icons/io';
import { HiMinusCircle } from 'react-icons/hi';
import { FaCamera } from "react-icons/fa";
import axios from 'axios';
import { useSnackbar } from 'notistack';
import Scrollbars from 'react-custom-scrollbars';

import { CenterPanel, HBox, LeftPanel, RightPanel, VBox } from '../../../components/Containers'
import Footer from '../../../layouts/Footer';
import colors from '../../../config/colors';
import { H3, H5, H6, P1, P2, P3 } from '../../../components/Typography';
import { InputText, InputTextarea } from '../../../components/InputText';
import PictureUploadDlg  from '../../Doctors/components/PictureUploadDlg';
import { Button } from '../../../components/Buttons';
import { specialtiesData, doctorTitleData, doctorBanglaTitleData,
         doctorDesignationData, mobileBankingOperatorData } from '../../../data';

const Container = styled(HBox)``;

const Paper = styled.div`
    width: 100%;
    border-radius: 10px;
`

const AddPhoto = styled(FaCamera)`
    color: ${colors.red};
    font-size: 25px;
    cursor: pointer;
    margin-left: 8px;
    :hover {
        color: ${colors.darkRed};
    }
`

const SpecialtiesHtml = ({ specialtyValues, setSpecialtyValues }) => {
    const handleSpecialtyChange = (index, event) => {
        let array = [...specialtyValues];
        array[index][event.target.name] = event.target.value;
        setSpecialtyValues(array);
    }
    const onDelete = (index) => {
        let array = [...specialtyValues];
        array.splice(index, 1);
        setSpecialtyValues(array)
    }
    return (
        <>
            {
                specialtyValues.map((specialty, index) => (
                    <HBox key={index} align='center' className='mt-2 ml-1'>
                        <InputText 
                            list='specialty-list'
                            label='Specialty' 
                            style={{width: '50%'}} 
                            value={specialty.specialty} 
                            name='specialty' 
                            onChange={(event) => handleSpecialtyChange(index, event)}
                        />
                        <datalist id='specialty-list'>
                            {
                                specialtiesData.map((specialty, index) => {
                                    return <option key={index}>{specialty}</option>
                                })
                            }
                        </datalist>
                        {
                            index ?
                            <HiMinusCircle 
                                style={{ 
                                    fontSize: '24px', 
                                    margin: '6px', 
                                    color: colors.red, 
                                    cursor: 'pointer'
                                }}
                                onClick={() => onDelete(index)}
                            />
                        :
                            <IoMdAddCircle 
                                style={{ fontSize: '24px', margin: '6px', color: colors.green, cursor: 'pointer'}}
                                onClick={() => setSpecialtyValues([...specialtyValues, {specialty: ''}])}
                            />
                        }
                    </HBox>
                ))
            }
        </>
    )
}

const AffiliationsHtml = ({ affiliationValues, setAffiliationValues }) => {
    const [suggestedInstitutions, setSuggestedInstitutions] = useState([]);

    const handleAffiliationChange = (index, event) => {
        let array = [...affiliationValues];
        if (event.target.type === 'checkbox') {
            array[index][event.target.name] = event.target.checked;
        } else {
            array[index][event.target.name] = event.target.value;

            if (event.target.name === 'institution') getSuggestedInstutions(event.target.value);
        }
        setAffiliationValues(array);
    }
    const onDelete = (index) => {
        let array = [...affiliationValues];
        array.splice(index, 1);
        setAffiliationValues(array)
    }

    const getSuggestedInstutions = (text) => {
        axios({
            method: 'GET',
            url: `nhadmin/suggested-institution-list/`,
            params: {
                key: text
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            if (response.status === 200) {
                setSuggestedInstitutions(response.data);
            } else {
                console.log('SUGGESTED INSTITUTIONS GET FAILED', response.status);
            }
        })
        .catch((error) => {
            console.log('SUGGESTED INSTITUTIONS GET ERROR', error);
        })
    }

    return (
        <>
            {
                affiliationValues.map((affiliation, index) => (
                    <Paper key={index} className='p-3'>
                        <HBox align='center'>
                            {
                                index ?
                                <>
                                    <H3>Affiliations - {index}</H3>
                                    <HiMinusCircle 
                                        style={{ 
                                            fontSize: '24px', 
                                            margin: '6px', 
                                            color: colors.red, 
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => onDelete(index)}
                                    />
                                </>
                                :
                                <>
                                    <H3>Affiliations</H3>
                                    <IoMdAddCircle 
                                        style={{ fontSize: '24px', margin: '6px', color: colors.green, cursor: 'pointer'}}
                                        onClick={() => setAffiliationValues([...affiliationValues, {institution: '', designation: '', department: '', currently_working: false, show_in_summary: false}])}
                                    />
                                </>
                            }
                        </HBox>
                        <HBox align='center' className='mt-2 ml-1'>
                            <InputText 
                                label='Institution/Hospital' 
                                style={{width: '100%'}} 
                                name='institution'
                                list='institutions'
                                value={affiliation.institution}
                                onChange={(event) => handleAffiliationChange(index, event)}
                            />
                        </HBox>
                        <datalist id='institutions'>
                            {suggestedInstitutions.map((inst, index) => {
                                return <option
                                            key={inst.id}
                                            // value={inst.name}
                                        >{inst.name}</option>
                            })}
                        </datalist>
                        <HBox align='center' className='mt-2 ml-1'>
                            <InputText 
                                label='Designation' 
                                style={{width: '100%'}}
                                list='designation' 
                                name='designation' 
                                value={affiliation.designation}
                                onChange={(event) => handleAffiliationChange(index, event)}
                            />

                            <datalist id='designation'>
                                {doctorDesignationData.map((designation) => (
                                    <option>{designation}</option>
                                ))}
                            </datalist>
                        </HBox>
                        <HBox align='center' className='mt-2 ml-1'>
                            <InputText 
                                label='Department'
                                list='department'
                                style={{width: '100%'}} 
                                name='department'
                                value={affiliation.department}
                                onChange={(event) => handleAffiliationChange(index, event)}
                            />

                            <datalist id='department'>
                                {specialtiesData.map((specialty) => (
                                    <option>Dept. of {specialty}</option>
                                ))}
                            </datalist>
                        </HBox>
                        <HBox align='center' className='mt-2'>
                            <input 
                                className='ml-1 mr-1' 
                                type='checkbox' 
                                name='currently_working'
                                checked={affiliation.currently_working}
                                onChange={(event) => handleAffiliationChange(index, event)}
                            /> Present
                        </HBox>
                        <HBox align='center' className='mt-1'>
                            <input 
                                className='ml-1 mr-1' 
                                type='checkbox' 
                                name='show_in_summary'
                                checked={affiliation.show_in_summary}
                                onChange={(event) => handleAffiliationChange(index, event)}
                            /> Highlight in doctor's profile
                        </HBox>
                    </Paper>
                ))
            }
        </>
    )
}

const ChambersHtml = ({ chamberValues, setChamberValues }) => {
    const handleChamberChange = (index, event) => {
        let array = [...chamberValues];
        array[index][event.target.name] = event.target.value;
        setChamberValues(array);
    }
    const onDelete = (index) => {
        let array = [...chamberValues];
        array.splice(index, 1);
        setChamberValues(array)
    }
    return (
        <>
            {
                chamberValues.map((chamber, index) => (
                    <HBox key={index} align='center' className='mt-2 ml-1'>
                        <InputText 
                            label='Chamber' 
                            style={{width: '50%'}}
                            value={chamber.chamber}
                            name='address'
                            onChange={(event) => handleChamberChange(index, event)}
                        />
                        <HiMinusCircle 
                            style={{ 
                                fontSize: '24px', 
                                margin: '6px', 
                                color: colors.red, 
                                cursor: 'pointer'
                            }}
                            onClick={() => onDelete(index)}
                        />
                    </HBox>
                ))
            }
        </>
    )
}

const ConsultationTimeHtml = ({ consultationTimeValues, setConsultationTimeValues}) => {
    const handleConsultationTimeChange = (index, event) => {
        let array = [...consultationTimeValues];
        if (event.target.type === 'checkbox') {
            array[index]['schedule'][event.target.name] = event.target.checked;
        } else {
            array[index]['schedule'][event.target.name] = event.target.value;
        }
        setConsultationTimeValues(array);
    }
    const onDelete = (index) => {
        let array = [...consultationTimeValues];
        array.splice(index, 1);
        setConsultationTimeValues(array)
    }
    return (
        <>
            {
                consultationTimeValues.map((consultationTime, index) => (
                    <Paper key={index} className='p-3'>
                        <HBox align='center' >
                            {
                                index ?
                                <>
                                <H3>Consultation Time - {index}</H3>
                                <HiMinusCircle 
                                    style={{ 
                                        fontSize: '24px', 
                                        margin: '6px', 
                                        color: colors.red, 
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => onDelete(index)}
                                />
                                </>
                                :
                                <>
                                <H3>Consultation Time</H3>
                                <IoMdAddCircle 
                                    style={{ fontSize: '24px', margin: '6px', color: colors.green, cursor: 'pointer'}}
                                    onClick={() => setConsultationTimeValues([...consultationTimeValues, {id: null, schedule: {
                                        startTime: '',
                                        endTime: '',
                                        friday: false,
                                        saturday: false,
                                        sunday: false,
                                        monday: false,
                                        tuesday: false,
                                        wednesday: false,
                                        thursday: false
                                    }}])}
                                />
                                </>
                            }
                        </HBox>
                        
                        <HBox className='ml-1' justify='space-between'>
                            <HBox>
                                <input 
                                    className='mr-1' 
                                    type='checkbox' 
                                    name='friday'
                                    checked={consultationTime.friday}
                                    onChange={(event) => handleConsultationTimeChange(index, event)}
                                /> Friday
                            </HBox>
                            <HBox>
                                <input 
                                    className='mr-1' 
                                    type='checkbox'
                                    name='saturday' 
                                    checked={consultationTime.saturday}
                                    onChange={(event) => handleConsultationTimeChange(index, event)}
                                /> Saturday
                            </HBox>
                            <HBox>
                                <input 
                                    className='mr-1' 
                                    type='checkbox'
                                    name='sunday'
                                    checked={consultationTime.sunday}
                                    onChange={(event) => handleConsultationTimeChange(index, event)} 
                                /> Sunday
                            </HBox>
                            <HBox>
                                <input 
                                    className='mr-1' 
                                    type='checkbox'
                                    name='monday' 
                                    checked={consultationTime.monday}
                                    onChange={(event) => handleConsultationTimeChange(index, event)}
                                /> Monday
                            </HBox>
                            <HBox>
                                <input 
                                    className='mr-1' 
                                    type='checkbox' 
                                    name='tuesday'
                                    checked={consultationTime.tuesday}
                                    onChange={(event) => handleConsultationTimeChange(index, event)}
                                /> Tuesday
                            </HBox>
                            <HBox>
                                <input 
                                    className='mr-1' 
                                    type='checkbox' 
                                    name='wednesday'
                                    checked={consultationTime.wednesday}
                                    onChange={(event) => handleConsultationTimeChange(index, event)}
                                /> Wednesday
                            </HBox>
                            <HBox>
                                <input 
                                    className='mr-1' 
                                    type='checkbox' 
                                    name='thursday'
                                    checked={consultationTime.thursday}
                                    onChange={(event) => handleConsultationTimeChange(index, event)}
                                /> Thursday
                            </HBox>
                        </HBox>
                        
                        <HBox align='center' className='mt-2 ml-1'>
                            <InputText 
                                type='time'
                                label='Start Time' 
                                name='startTime'
                                value={consultationTime.startTime}
                                style={{ width: 'calc(50% - 2px)' }}
                                className="mr-0_5"
                                onChange={(event) => handleConsultationTimeChange(index, event)}
                            />
                            <InputText 
                                type='time'
                                label='End Time' 
                                name='endTime'
                                value={consultationTime.endTime}
                                style={{ width: 'calc(50% - 2px)' }}
                                onChange={(event) => handleConsultationTimeChange(index, event)}
                            />
                        </HBox>
                    </Paper>
                ))
            }
        </>
    )
}

const Form = () => {
    const phonePrefix = '+88';
    const [uploadPicOpen, setUploadPicOpen] = useState(false);
    const [uploadSignOpen, setUploadSignOpen] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);

    const [image, setImage] = useState('');
    const [sign, setSign] = useState('');
    const [doctorType, setDoctorType] = useState('');
    const [title, setTitle] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [banglaTitle, setBanglaTitle] = useState('');
    const [fullNameBangla, setFullNameBangla] = useState('');
    const [gender, setGender] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [assistantPhone, setAssistantPhone] = useState('');
    const [contactPreference, setContactPreference] = useState({ personal: false, assistant: false });
    const [email, setEmail] = useState('');
    const [bmdc, setBmdc] = useState('');
    const [qualification, setQualification] = useState('');
    const [trainings, setTrainings] = useState('');
    const [experience, setExperience] = useState('');
    const [specialtyValues, setSpecialtyValues] = useState([{specialty: ''}]);
    const [affiliationValues, setAffiliationValues] = useState([{institution: '', designation: '', department: '', currently_working: false, show_in_summary: false}]);
    const [chamberValues, setChamberValues] = useState([]);
    const [consultationTimeValues, setConsultationTimeValues] = useState([{id: null, schedule: {
        startTime: '',
        endTime: '',
        friday: false,
        saturday: false,
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false
    }}]);
    const [regularFee, setRegularFee] = useState('');
    const [followUpFee, setFollowUpFee] = useState('');
    const [followUpDuration, setFollowUpDuration] = useState('');
    const [accountType, setAccountType] = useState({ savings: false, current: false });
    const [accountName, setAccountName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [bankName, setBankName] = useState('');
    const [branchName, setBranchName] = useState('');
    const [relToAccHolder, setRelToAccHolder] = useState('');
    const [mobileBankingNumber, setMobileBankingNumber] = useState('');
    const [operator, setOperator] = useState('');
    const [altMobileBankingNumber, setAltMobileBankingNumber] = useState('');
    const [altOperator, setAltOperator] = useState('');

    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setImage('')
        setSign('')
        setDoctorType('')
        setTitle('')
        setFirstName('')
        setLastName('')
        setBanglaTitle('')
        setFullNameBangla('')
        setGender('')
        setAddress('')
        setPhone('')
        setAssistantPhone('')
        setContactPreference({ personal: false, assistant: false })
        setEmail('')
        setBmdc('')
        setQualification('')
        setTrainings('')
        setExperience('')
        setSpecialtyValues([{specialty: ''}])
        setAffiliationValues([{institution: '', designation: '', department: '', currently_working: false, show_in_summary: false}])
        setChamberValues([])
        setConsultationTimeValues([{id: null, schedule: {
            startTime: '',
            endTime: '',
            friday: false,
            saturday: false,
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false
        }}])
        setRegularFee('')
        setFollowUpFee('')
        setFollowUpDuration('')
        setAccountType({ savings: false, current: false })
        setAccountName('')
        setAccountNumber('')
        setBankName('')
        setBranchName('')
        setRelToAccHolder('')
        setMobileBankingNumber('')
        setOperator('')
        setAltMobileBankingNumber('')
        setAltOperator('')
        setShowError(false)
        setErrorMessage('')
        closeSnackbar();
    }, []);

    const validate = () => {
        let validated = true;
        let erMsg = '';

        if(!doctorType) {
            validated = false;
            erMsg = 'Doctor type can not be kept blank.';
        }

        if(!title) {
            validated = false;
            erMsg = 'Title can not be kept blank.';
        }

        if (!firstName) {
            validated = false;
            erMsg = 'First Name can not be kept blank.';
        }

        if (!lastName) {
            validated = false;
            erMsg = 'Last Name can not be kept blank.';
        }

        if (!phone) {
            validated = false;
            erMsg = 'Phone Number can not be kept blank.';
        }
        else if (phone.match(/^01\d{9}$/) === null) {
            validated = false;
            erMsg = "Please enter a valid phone number.";
        }

        if (assistantPhone) {
            if (assistantPhone.match(/^01\d{9}$/) === null) {
                validated = false;
                erMsg = "Please enter a valid assistant phone number.";
            }
        }

        if (email) {
            if (!(email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) || email === "")) {
                validated = false;
                erMsg = "Please put a valid email address or clear if not necessary.";
            }
        }

        if (bmdc.trim() === "") {
            validated = false;
            erMsg = "BMDC can not be kept blank.";
        }
        else if (bmdc.includes('A-')) {
            validated = false;
            erMsg = "Please exclude 'A-' from BMDC.";
        }
        else if (bmdc.match(/^[0-9\b]+$/) === null) {
            validated = false;
            erMsg = "Please put only numeric digits in BMDC. Ex: 1234.";
        }

        if (regularFee==='') {
            validated = false;
            erMsg = 'Regular Fee can not be kept blank.';
        }


        if (followUpFee==='') {
            validated = false;
            erMsg = 'Follow Up Fee can not be kept blank.';
        }

        if (followUpDuration==='') {
            validated = false;
            erMsg = 'Follow Up Duration can not be kept blank.';
        }

        if (!validated) {
            setShowError(true);
            setErrorMessage(erMsg);
            enqueueSnackbar(erMsg, { variant : 'error' });
        }
        
        return validated;
    }

    const handleChangeDoctorType = (event) => {
        setDoctorType(event.target.value)
    }
    
    const handleChangeTitle = (event) => {
        setTitle(event.target.value)
    }

    const handleChangeFirstName = (event) => {
        setFirstName(event.target.value)
    }

    const handleChangeLastName = (event) => {
        setLastName(event.target.value)
    }

    const handleChangeBanglaTitle = (event) => {
        setBanglaTitle(event.target.value)
    }

    const handleChangeFullNameBangla = (event) => {
        setFullNameBangla(event.target.value)
    }

    const handleChangeGender = (event) => {
        setGender(event.target.value)
    }

    const handleChangeAddress = (event) => {
        setAddress(event.target.value)
    }

    const handleChangePhone = (event) => {
        setPhone(event.target.value)
    }

    const handleChangeAssistantPhone = (event) => {
        setAssistantPhone(event.target.value);
    }

    const handleChangeContactPref = (event) => {
        setContactPreference({personal: false, assistant: false, [event.target.name]: event.target.checked});
    }

    const handleChangeEmail = (event) => {
        setEmail(event.target.value)
    }

    const handleChangeQualification = (event) => {
        setQualification(event.target.value)
    }

    const handleChangeTrainings = (event) => {
        setTrainings(event.target.value)
    }

    const handleChangeBmdc= (event) => {
        setBmdc(event.target.value)
    }

    const handleChangeExperience = (event) => {
        setExperience(event.target.value)
    }

    const handleChangeRegularFee = (event) => {
        setRegularFee(event.target.value)
    }

    const handleChangeFollowupFee = (event) => {
        setFollowUpFee(event.target.value)
    }

    const handleChangeFollowupDuration = (event) => {
        setFollowUpDuration(event.target.value)
    }

    const handleChangeAccountType = (event) => {
        setAccountType({savings: false, current: false, [event.target.name]: event.target.checked});
    }

    const handleChangeAccountName = (event) => {
        setAccountName(event.target.value)
    }

    const handleChangeAccountNumber = (event) => {
        setAccountNumber(event.target.value)
    }

    const handleChangeBankName = (event) => {
        setBankName(event.target.value)
    }

    const handleChangeBranchName = (event) => {
        setBranchName(event.target.value)
    }

    const handleChangeRelToAccHolder = (event) => {
        setRelToAccHolder(event.target.value);
    }

    const handleChangeMobileBankingNumber = (event) => {
        setMobileBankingNumber(event.target.value)
    }

    const handleChangeOperator = (event) => {
        setOperator(event.target.value)
    }

    const handleChangeAltMobileBankingNumber = (event) => {
        setAltMobileBankingNumber(event.target.value)
    }

    const handleChangeAltOperator = (event) => {
        setAltOperator(event.target.value)
    }

    const handleAddPhoto = (blob) => {
        setImage(blob)
    }

    const handleAddSign = (blob) => {
        setSign(blob)
    }

    const submitForm = (status) => {
        var formData = new FormData();
        formData.append('image', image);
        formData.append('sign', sign);
        formData.append('doctor_type', doctorType);
        formData.append('title', title);
        formData.append('first_name', firstName);
        formData.append('last_name', lastName);
        formData.append('bangla_title', banglaTitle);
        formData.append('bangla_name', fullNameBangla);
        formData.append('gender', gender);
        formData.append('address', address);
        formData.append('phone', phonePrefix+phone);
        formData.append('assistant_phone', assistantPhone);
        formData.append('contact_preference', getContactPrefVal());
        formData.append('email', email);
        formData.append('bmdc', bmdc);
        formData.append('qualification', qualification);
        formData.append('trainings', trainings);
        formData.append('experience', experience);
        formData.append('specialty', JSON.stringify(specialtyValues));
        formData.append('affiliation', JSON.stringify(affiliationValues));
        formData.append('chamber', JSON.stringify(chamberValues));
        formData.append('consultation_time', JSON.stringify(consultationTimeValues));
        formData.append('regular_fee', regularFee);
        formData.append('followup_fee', followUpFee);
        formData.append('followup_duration', followUpDuration);
        formData.append('account_type', getAccountTypeVal());
        formData.append('account_name', accountName);
        formData.append('account_number', accountNumber);
        formData.append('bank_name', bankName);
        formData.append('branch_name', branchName);
        formData.append('relation_to_acc_holder', relToAccHolder);
        formData.append('mobile_banking_number', mobileBankingNumber);
        formData.append('mobile_banking_operator', operator);
        formData.append('alt_mobile_banking_number', altMobileBankingNumber);
        formData.append('alt_mobile_banking_operator', altOperator);
        formData.append('onboarding_status', status);

        if (validate()) {
            setIsLoading(true)
            enqueueSnackbar('Submitting...', { persist: true });
            axios({
                method: "POST",
                url: 'moderator/doctor-list/',
                data: formData,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('nh-access')}`,
                },
            })
            .then((response) => {
                console.log('#--- Doctor Add Success --- #', response.status);
                closeSnackbar();
                if (response.status === 201) {
                    setIsLoading(false)
                    enqueueSnackbar('Doctor successfully added.', { variant: 'success' })
                    setDoctorType('');
                    setImage('')
                    setSign('')
                    setBmdc('')
                    setTitle('')
                    setFirstName('')
                    setLastName('')
                    setBanglaTitle('')
                    setFullNameBangla('')
                    setGender('')
                    setAddress('')
                    setPhone('')
                    setAssistantPhone('')
                    setContactPreference({ personal: false, assistant: false })
                    setEmail('')
                    setBmdc('')
                    setQualification('')
                    setTrainings('')
                    setExperience('')
                    setSpecialtyValues([{specialty: ''}])
                    setAffiliationValues([{institution: '', designation: '', department: '', currently_working: false, show_in_summary: false}])
                    setChamberValues([])
                    setConsultationTimeValues([{id: null, schedule: {
                        startTime: '',
                        endTime: '',
                        friday: false,
                        saturday: false,
                        sunday: false,
                        monday: false,
                        tuesday: false,
                        wednesday: false,
                        thursday: false
                    }}])
                    setRegularFee('')
                    setFollowUpFee('')
                    setFollowUpDuration('')
                    setAccountType({ savings: false, current: false })
                    setAccountName('')
                    setAccountNumber('')
                    setBankName('')
                    setBranchName('')
                    setRelToAccHolder('')
                    setMobileBankingNumber('')
                    setOperator('')
                    setAltMobileBankingNumber('')
                    setAltOperator('')
                }
            })
            .catch((error) => {
                setIsLoading(false)
                closeSnackbar();
                console.log('#--- Doctor Add error --- #', error);
                if (error.request.status === 409) {
                    var errorJson = JSON.parse(error.request.responseText);
                    enqueueSnackbar(errorJson.error, { variant: 'error' })
                }
            })
        }
    }

    const getContactPrefVal = () => {
        if (contactPreference.personal) {
            return 'Personal';
        }
        else if (contactPreference.assistant) {
            return 'Assistant';
        }
        else {
            return '';
        }
    }

    const getAccountTypeVal = () => {
        if (accountType.savings) {
            return 'Savings';
        }
        else if (accountType.current) {
            return 'Current';
        }
        else {
            return '';
        }
    }

    return (
    <>
        <Container>
            <LeftPanel />
            <CenterPanel>
                <Scrollbars
                    style={{ height: 'calc(100vh - 150px)' }}
                    renderThumbVertical={({ style, ...props }) =>
                        <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '5px', borderRadius: '3px', opacity: '0.4' }} />
                    }
                >
                    <VBox className="p-3" style={{ width: '100%' }}>
                        <H5 className='ml-3'>(*) marked fields are mandatory</H5>
                        {showError ? <H5 className='ml-3 mt-2' style={{ color: colors.red }}>{errorMessage}</H5> : ''}
                        <Paper className='p-3'>
                            <H3>Personal Information</H3>
                            
                            <HBox align='center' className='mt-2 ml-1'>
                               <H5 style={{ fontWeight: 'normal'}}>Add Photo</H5> <AddPhoto onClick={() => setUploadPicOpen(true)}/>
                               {image ? <H6 className='ml-1' color='third'>File Added</H6> : null}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                               <H5 style={{ fontWeight: 'normal'}}>Add Signature</H5> <AddPhoto onClick={() => setUploadSignOpen(true)}/>
                               {sign ? <H6 className='ml-1' color='third'>File Added</H6> : null}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText
                                    label='Doctor Type *'
                                    list='doctor-type'
                                    style={{width: '50%'}}
                                    value={doctorType}
                                    onChange={(event) => handleChangeDoctorType(event)}
                                />
                                
                                <datalist id='doctor-type'>
                                    <option key='MBBS'>MBBS</option>
                                    <option key='BDS'>BDS</option>
                                </datalist>
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText
                                    label='Title *'
                                    list='title'
                                    style={{width: '100%'}}
                                    value={title}
                                    onChange={(event) => handleChangeTitle(event)}
                                />

                                <datalist id='title'>
                                    {doctorTitleData.map((title) => (
                                        <option>{title}</option>
                                    ))}
                                </datalist>
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='First Name *' 
                                    style={{width: '100%'}}
                                    value={firstName}
                                    onChange={(event) => handleChangeFirstName(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='Last Name *' 
                                    style={{width: '100%'}}
                                    value={lastName}
                                    onChange={(event) => handleChangeLastName(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='Title (Bangla)'
                                    list='title-bangla'
                                    style={{width: '100%'}}
                                    value={banglaTitle}
                                    onChange={(event) => handleChangeBanglaTitle(event)}
                                />

                                <datalist id='title-bangla'>
                                    {doctorBanglaTitleData.map((title) => (
                                        <option>{title}</option>
                                    ))}
                                </datalist>
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='Full Name (Bangla)' 
                                    style={{width: '100%'}}
                                    value={fullNameBangla}
                                    onChange={(event) => handleChangeFullNameBangla(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    list='gender'
                                    label='Gender' 
                                    style={{width: '100%'}}
                                    value={gender}
                                    onChange={(event) => handleChangeGender(event)}
                                />

                                 <datalist id='gender'>
                                    <option key='MBBS'>Male</option>
                                    <option key='BDS'>Female</option>
                                    <option key='MBBS'>Other</option>
                                </datalist>
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='Address' 
                                    style={{width: '100%'}}
                                    value={address}
                                    onChange={(event) => handleChangeAddress(event)}
                                />
                            </HBox>
                        </Paper>
                        <Paper className='p-3'>
                            <H3>Contact Information</H3>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='Phone *' 
                                    style={{width: '100%'}}
                                    value={phone}
                                    onChange={(event) => handleChangePhone(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label="Assistant's Phone" 
                                    style={{width: '100%'}}
                                    value={assistantPhone}
                                    onChange={(event) => handleChangeAssistantPhone(event)}
                                />
                            </HBox>
                            <HBox className='mt-2 ml-1'>
                                <P2>Contact Preference</P2>
                                <HBox className='ml-2'>
                                    <input 
                                        className='mr-1' 
                                        type='checkbox' 
                                        name='personal'
                                        checked={contactPreference.personal}
                                        onChange={(event) => handleChangeContactPref(event)}
                                    /> Personal
                                </HBox>
                                <HBox className='ml-2'>
                                    <input 
                                        className='mr-1' 
                                        type='checkbox'
                                        name='assistant' 
                                        checked={contactPreference.assistant}
                                        onChange={(event) => handleChangeContactPref(event)}
                                    /> Assistant
                                </HBox>
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='Email' 
                                    style={{width: '100%'}}
                                    value={email}
                                    onChange={(event) => handleChangeEmail(event)}
                                />
                            </HBox>
                        </Paper>
                        <Paper className='p-3'>
                            <H3>Professional Information</H3>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='BMDC Reg. No. *' 
                                    style={{width: '100%'}}
                                    value={bmdc}
                                    onChange={(event) => handleChangeBmdc(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='Academic Qualification' 
                                    style={{width: '100%'}}
                                    value={qualification}
                                    onChange={(event) => handleChangeQualification(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='Post-Grad Trainings' 
                                    style={{width: '100%'}}
                                    value={trainings}
                                    onChange={(event) => handleChangeTrainings(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='Years of Experience' 
                                    style={{width: '100%'}}
                                    value={experience}
                                    onChange={(event) => handleChangeExperience(event)}
                                />
                            </HBox>
                            <VBox>
                                <SpecialtiesHtml 
                                    specialtyValues={specialtyValues}
                                    setSpecialtyValues={setSpecialtyValues}
                                />
                            </VBox>
                        </Paper>
                        <VBox>
                            <AffiliationsHtml 
                                affiliationValues={affiliationValues}
                                setAffiliationValues={setAffiliationValues}
                            />
                        </VBox>
                        <Paper className='p-3'>
                            <HBox align='center'>
                                <H3>Chambers</H3>
                                <IoMdAddCircle 
                                    style={{ fontSize: '24px', margin: '6px', color: colors.green, cursor: 'pointer'}}
                                    onClick={() => setChamberValues([...chamberValues, {address: ''}])}
                                />
                            </HBox>
                            <VBox>
                                <ChambersHtml
                                    chamberValues={chamberValues}
                                    setChamberValues={setChamberValues}
                                />
                            </VBox>
                        </Paper>
                        <VBox>
                            <ConsultationTimeHtml 
                                consultationTimeValues={consultationTimeValues}
                                setConsultationTimeValues={setConsultationTimeValues}
                            />
                        </VBox>
                        <Paper className='p-3'>
                            <H3>Fees</H3>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='Regular Fee *'
                                    type='number'
                                    style={{width: '100%'}}
                                    value={regularFee}
                                    onChange={(event) => handleChangeRegularFee(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='Follow Up Fee *'
                                    type='number' 
                                    style={{width: '100%'}}
                                    value={followUpFee}
                                    onChange={(event) => handleChangeFollowupFee(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='Follow Up Duration *'
                                    type='number' 
                                    style={{width: '100%'}}
                                    value={followUpDuration}
                                    onChange={(event) => handleChangeFollowupDuration(event)}
                                />
                            </HBox>
                        </Paper>
                        <Paper className='p-3'>
                            <H3>Bank Information</H3>
                            <HBox className='mt-2 ml-1'>
                                <P2>Account Type</P2>
                                <HBox className='ml-2'>
                                    <input 
                                        className='mr-1' 
                                        type='checkbox' 
                                        name='savings'
                                        checked={accountType.savings}
                                        onChange={(event) => handleChangeAccountType(event)}
                                    /> Savings
                                </HBox>
                                <HBox className='ml-2'>
                                    <input 
                                        className='mr-1' 
                                        type='checkbox'
                                        name='current' 
                                        checked={accountType.current}
                                        onChange={(event) => handleChangeAccountType(event)}
                                    /> Current
                                </HBox>
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='A/C Name' 
                                    style={{width: '100%'}}
                                    value={accountName}
                                    onChange={(event) => handleChangeAccountName(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='A/C Nnumber' 
                                    style={{width: '100%'}}
                                    value={accountNumber}
                                    onChange={(event) => handleChangeAccountNumber(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='Name of Bank' 
                                    style={{width: '100%'}}
                                    value={bankName}
                                    onChange={(event) => handleChangeBankName(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='Branch' 
                                    style={{width: '100%'}}
                                    value={branchName}
                                    onChange={(event) => handleChangeBranchName(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='Relation to A/C Holder (if not personal)' 
                                    style={{width: '100%'}}
                                    value={relToAccHolder}
                                    onChange={(event) => handleChangeRelToAccHolder(event)}
                                />
                            </HBox>
                        </Paper>
                        <Paper className='p-3'>
                            <H3>Mobile Banking Information</H3>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='Mobile A/C Number' 
                                    style={{width: '100%'}}
                                    value={mobileBankingNumber}
                                    onChange={(event) => handleChangeMobileBankingNumber(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='Operator'
                                    list='operator'
                                    style={{width: '100%'}}
                                    value={operator}
                                    onChange={(event) => handleChangeOperator(event)}
                                />

                                <datalist id='operator'>
                                    {mobileBankingOperatorData.map((op) => (
                                        <option>{op}</option>
                                    ))}
                                </datalist>
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='Mobile A/C Number (Alternative)' 
                                    style={{width: '100%'}}
                                    value={altMobileBankingNumber}
                                    onChange={(event) => handleChangeAltMobileBankingNumber(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='Operator (Alternative)'
                                    list='operator-alt' 
                                    style={{width: '100%'}}
                                    value={altOperator}
                                    onChange={(event) => handleChangeAltOperator(event)}
                                />

                                <datalist id='operator-alt'>
                                    {mobileBankingOperatorData.map((op) => (
                                        <option>{op}</option>
                                    ))}
                                </datalist>
                            </HBox>
                        </Paper>
                        <HBox justify='center' className='mt-2 ml-1'>
                            <Button 
                                color='third'
                                onClick={() => submitForm('Draft')}
                            >
                                Save as Draft
                            </Button>
                            <Button 
                                className='ml-2'
                                color='second'
                                onClick={() => submitForm('On Hold')}
                            >
                                Send Onboard Request
                            </Button>
                        </HBox>
                    </VBox>
                    
                </Scrollbars>
                <PictureUploadDlg
                    open={uploadPicOpen}
                    setOpen={setUploadPicOpen}
                    handleAddPhoto={handleAddPhoto}
                />
                <PictureUploadDlg
                    open={uploadSignOpen}
                    setOpen={setUploadSignOpen}
                    handleAddPhoto={handleAddSign}
                    aspect={3.75}
                    title='Update Signature'
                />
            </CenterPanel>
            <RightPanel />
            
        </Container>
        {/* <Footer /> */}
    </>
  )
}

export default Form