import { useState, useEffect, forwardRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';

import { login } from "../../../services/actions/authAction";
import { HBox, VBox } from "../../../components/Containers";
import { InputText } from "../../../components/InputText";
import { H2, H3, H4, H6, P1, P2, P3 } from "../../../components/Typography";
import { Button } from "../../../components/Buttons";
import Alert from "../../../components/Alert";
import colors from "../../../config/colors";
import MyHealthLogo from '../../../assets/images/logo_main.png';

const FormContainer = styled.div`
    //width: 25vw;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
    background: linear-gradient(180deg, #ffffff 0%, hsl(0, 0%, 97%) 100%);
    overflow: visible;
    border-radius: 10px;
    border: 0px solid #222;

    @media only screen and (max-width: 599px) { // xs
        width: 80vw;
    }
    @media only screen and (min-width: 600px) and (max-width: 899px) {  // sm
        width: 50vw;
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {  // md
        width: 35vw;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1535px) {  // lg
        width: 25vw;
    }
    @media only screen and (min-width: 1536px) {  // xl
        width: 25vw;
    }
`

const SLink = styled(Link)`
    color: ${colors.darkBlue};
`

const CountryCodeContainer = styled(HBox)`
    width: 20%;
    border-radius: 5px;
    background-color: white;
    border: 1px solid ${colors.grey};
    color: ${colors.darkGrey};
    padding: 7px;
`

const Form = ({ networkError, loginError, login }) => {
    var phonePrefix = '+88';
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [loginWith, setLoginWih] = useState(null);
    const [phoneOrEmail, setPhoneOrEmail] = useState("");
    const [password, setPassword] = useState("");

    const [phoneOrEmailError, setPhoneOrEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    useEffect(() => {
        setPassword("");
        setPhoneOrEmailError("");
        setPasswordError("");
        closeSnackbar();
    }, []);

    const validate = () => {
        var ok = true;

        if (phoneOrEmail.includes('@')) {
            if (phoneOrEmail.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                setPhoneOrEmailError("");
            }
            else {
                setPhoneOrEmailError("Please put a valid email address.")
                ok = false;
            }
        }
        else if (phoneOrEmail.match(/^(?:[+\d].*\d|\d)$/)) {
            if (phoneOrEmail.match(/^01\d{9}$/)) {
                setPhoneOrEmailError("");
            }
            else if (phoneOrEmail.match(/^\+8801\d{9}$/)) {
                setPhoneOrEmailError("Please omit (+88) from your phone number.");
                ok = false;
            }
            else {
                setPhoneOrEmailError("Please enter a valid phone number.");
                ok = false;
            }
        }
        else {
            setPhoneOrEmailError("Username format is incorrect.");
            ok = false;
        }

        if (password) {
            setPasswordError("");
        }
        else {
            setPasswordError("This field cannot be kept blank.");
            ok = false;
        }

        return ok;
    }

    const handleClickLogin = () => {
        enqueueSnackbar('Logging in...', { persist: true });

        let loginWith = phoneOrEmail.includes('@') ? 'email' : 'phone';
        login(loginWith === 'phone' ? phonePrefix+phoneOrEmail : phoneOrEmail, password, loginWith);
    }

    return (
        <div>
            <FormContainer className='p-3' >
                <img
                    style={{ height: 90, alignSelf: 'center' }}
                    className=""
                    src={MyHealthLogo}
                    alt="logo"
                />
                <H4 color='' align='center' className="mb-3">Login to Moderator Portal</H4>
                {networkError && <>{closeSnackbar()} <Alert className='mb-2'>Network error.</Alert></>}
                {loginError && <>{closeSnackbar()} <Alert className='mb-2'>Username or password incorrect.</Alert></>}
                
                <InputText
                    label="Phone or email"
                    type='text'
                    helpText={phoneOrEmailError}
                    value={phoneOrEmail}
                    onChange={e => setPhoneOrEmail(e.target.value)}
                />
                <InputText
                    className="mt-1"
                    label='Password'
                    type='password'
                    helpText={passwordError}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
                <HBox justify='space-between' align='center' className='mt-2'>
                    <SLink to="/forget-password">
                        <P2 color='first'>Forgot password?</P2>
                    </SLink>
                    <Button
                        size='sm'
                        color='first'
                        onClick={() => {validate() && handleClickLogin()}}
                    >
                        Login
                    </Button>
                </HBox>
            </FormContainer>
            {/* <FormContainer className='mt-2 p-2'>
                <P2 align='center'>Don't have an account? <SLink to='/register'>Register</SLink></P2>
            </FormContainer> */}
        </div>
    )
}

const mapStateToProps = state => ({
    networkError: state.auth.networkError,
    loginError: state.auth.loginError,
});

export default connect(mapStateToProps, { login })(Form);