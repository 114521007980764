import styled from "styled-components";
import { Navigate } from "react-router-dom";
import { connect } from 'react-redux';
import LoginBackground from '../../assets/images/loginBg.jpg';

import Form from "./components/Form";

const ImageBG = styled.div`
    width: 100vw;
    height: 100vh;
    background-image: url(${LoginBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`

const Overlay = styled.div`
    width: 100vw;
    height: 100vh;
    background: linear-gradient(248deg, #c2f2ff 0%, rgb(19, 176, 221) 100%);
    opacity: 0.91;
    display: flex;
    justify-content: center;
    align-items: center;
    position: 'absolute';
    top: 0;
`

const LoginContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
`

const Login = ({ isAuthenticated, isVerified }) => {
    if (!isVerified && isAuthenticated) {
        return (<Navigate to="/passcode-verification" />);
    }
    else if (isAuthenticated) {
        return (<Navigate to="/appointments" />);
    }

    return (
        <ImageBG>
            <Overlay />
            <LoginContainer>
                <Form />
            </LoginContainer>
        </ImageBG>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    isVerified: state.auth.isVerified,
});

export default connect(mapStateToProps, { })(Login);
