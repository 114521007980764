import React from 'react'
import { LowerNav } from '../../layouts/Navbar'
import Form from './components/Form'
import Footer from '../../layouts/Footer'

const AddDoctors = () => {
  return (
    <>
        <LowerNav selected='add' />
        <Form />
        <Footer />
    </>
  )
}

export default AddDoctors