import { useState, useEffect, forwardRef } from 'react';
import styled from "styled-components";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Table, TableHead, TableBody, TableCell, TableFooter,
         TablePagination, TableRow, IconButton, Tooltip } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import axios from "axios";
import moment from "moment";
import { useSnackbar } from 'notistack';
import { FiFilter, FiDownload, FiEye } from 'react-icons/fi';

import { VBox, HBox } from '../../../components/Containers';
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import { InputText } from '../../../components/InputText';
import { Button } from '../../../components/Buttons';
import colors from "../../../config/colors";
import noImage from "../../../assets/images/noImage.png";
import PatientFilterDlg from './PatientFilterDlg';
import PatientDetailsDlg from './PatientDetailsDlg';

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const Image = styled.img`
    height: 50px;
    width: 50px;
    border-radius: 25px;
    border: 1px solid ${colors.grey};
`

const LoadingTxt = styled(P3)`
    border: 1px solid ${colors.grey};
    border-radius: 3px;
    padding: 4px;
`

const FilterIcon = styled(FiFilter)`
    // size: 16px;
    color: ${colors.red};
`

const OnlineIcon = styled.div`
    background-color: ${colors.green};
    height: 8px;
    width: 8px;
    border-radius: 4px;
`

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        console.log('next button', count);
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <HBox style={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </HBox>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const PatientTable = ({  }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [searchKey, setSearchKey] = useState("");
    const [patients, setPatients] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [count, setCount] = useState(null);
    const [inputTimeout, setInputTimeout] = useState(null);

    const [openFilterBy, setOpenFilterBy] = useState(false);
    const [openPtProfile, setOpenPtProfile] = useState(false);
    const [selectedPtId, setSelectedPtId] = useState(null);

    const [ordering, setOrdering] = useState({orderBy: 'created_at', direction: '-'});
    const [filtering, setFiltering] = useState({
        gender: '',
    });

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        enqueueSnackbar("Loading...", { persist: true });
        getData();
    }, []);
    useEffect(() => {
        getData();
    }, [page, rowsPerPage]);
    useEffect(() => {
        handleSearch();
    }, [searchKey]);
    useEffect(() => () => clearTimeout(inputTimeout), [inputTimeout]);
    useEffect(() => {
        reloadData();
    }, [ordering, filtering]);

    const getData = () => {
        setIsLoading(true);
        axios({
            method: 'GET',
            url: 'nhadmin/patient-list/',
            params: {
                key: searchKey,
                gender: filtering.gender,
                order_by: ordering.orderBy,
                direction: ordering.direction,
                offset: page*rowsPerPage,
                limit: page*rowsPerPage+rowsPerPage
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            closeSnackbar();
            if (response.status === 200) {
                setPatients(response.data.patients);
                setCount(response.data.count);
            } else {
                console.log('PATIENT LIST FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            closeSnackbar();
            console.log('PATIENT LIST FETCH ERROR', error);
        })
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const handleSearch = () => {
        if (inputTimeout) clearTimeout(inputTimeout);
        setInputTimeout(
                setTimeout(() => {
                    setPage(0);
                    getData();
            }, 300)
        )
    }

    const reloadData = () => {
        setPage(0);
        getData();
    }

    const clearFilter = () => {
        setFiltering({
            gender: '', 
        });
    }

    const handleExportPatientsExcel = () => {
        enqueueSnackbar('Preparing to download... Please wait a moment.', { persist: true });
        axios({
            method: 'GET',
            url: 'nhadmin/patients-export-excel/',
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            closeSnackbar();
            if (response.status === 200) {
                const href = URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `patients_${moment().format('YYYY-MM-DD')}.xlsx`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            } else {
                enqueueSnackbar('Something went wrong. Please try again.', {variant: 'error'});
                console.log('PATIENTS EXPORT TO EXCEL FAILED', response.status);
            }
        })
        .catch((error) => {
            closeSnackbar();
            enqueueSnackbar('Something went wrong. Please try again.', {variant: 'error'});
            console.log('PATIENTS EXPORT TO EXCEL ERROR', error);
        })
    }

    return (
        <>
        <HBox justify="space-between" align="center" className='mb-1'>
            <HBox align='center'>
                <H3 color='second' align='left'>Patients Table</H3>
                <Button
                    className='ml-1'
                    size='xs'
                    color='first'
                    outlined
                    onClick={handleExportPatientsExcel}
                    style={{ paddingLeft: 8, paddingRight: 8 }}
                >
                    <FiDownload className='mr-0_5' />
                    Excel
                </Button>
                {isLoading && <LoadingTxt className='ml-1'>Loading...</LoadingTxt>}
            </HBox>
            <HBox>
                <Tooltip title='Filtering' arrow>
                    <IconButton onClick={() => setOpenFilterBy(true)}>
                        <FilterIcon />
                    </IconButton>
                </Tooltip>
                <InputText
                    className='ml-1'
                    value={searchKey}
                    onChange={(e) => setSearchKey(e.target.value)}
                    placeholder="Search for a keyword..."
                />
            </HBox>
        </HBox>
        <VBox style={{ border: `1px solid ${colors.grey}`, borderRadius: 5 }}>
            <Table sx={{ minWidth: 500 }} size='small'>
                <TableHead style={{ backgroundColor: colors.lightGrey }}>
                    <TableRow>
                        <TableCell className='bold'>Photo</TableCell>
                        <TableCell className='bold'>ID</TableCell>
                        <TableCell className='bold'>Patient Name</TableCell>
                        <TableCell className='bold'>Phone</TableCell>
                        <TableCell className='bold'>Email</TableCell>
                        <TableCell className='bold'>Added At</TableCell>
                        <TableCell className='bold'>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {patients.map((patient, index) => (
                        <TableRow key={patient.id} style={{ height: 33 }}>
                            <TableCell>
                                <Image
                                    src={patient.image ? `${baseURL}${patient.image}` : noImage}
                                    // onError={({ currentTarget }) => {
                                    //     currentTarget.onerror = null;
                                    //     currentTarget.src = "/images/noImage.svg";
                                    // }}
                                />
                            </TableCell>
                            <TableCell>
                                {patient.id}
                            </TableCell>
                            <TableCell className='bold'>
                                <HBox align='center'>
                                    {patient.full_name}
                                    {patient.is_online && <Tooltip title='Online' arrow><OnlineIcon className='ml-0_5' /></Tooltip>}
                                </HBox>
                            </TableCell>
                            <TableCell>
                                {patient.phone}
                            </TableCell>
                            <TableCell>
                                {patient.email ? patient.email : "Not given"}
                            </TableCell>
                            <TableCell>
                                {moment(patient.created_at).format('LLL')}
                            </TableCell>
                            <TableCell style={{ width: 150 }}>
                                <Tooltip title='Details' arrow>
                                    <IconButton>
                                        <FiEye
                                            fontSize={16}
                                            style={{ color: colors.blue }}
                                            onClick={() => {setSelectedPtId(patient.id);
                                                            setOpenPtProfile(true)}}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}

                    {emptyRows > 0 && (
                        <TableRow style={{ height: 33 * emptyRows }}>
                            <TableCell colSpan={7} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, 30]}
                            colSpan={7}
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </VBox>
        
        <PatientDetailsDlg
            open={openPtProfile}
            setOpen={setOpenPtProfile}
            patientId={selectedPtId}
        />
        <PatientFilterDlg
            open={openFilterBy}
            setOpen={setOpenFilterBy}
            ordering={ordering}
            setOrdering={setOrdering}
            filtering={filtering}
            setFiltering={setFiltering}
            clearFilter={clearFilter}
        />
        </>
    );
}

export default PatientTable;