export const specialtiesData = [
    "General Physician",
    // Medicine
    "Internal Medicine",
    "Cardiology",
    "Respiratory Medicine",
    "Neurology",
    "Nephrology",
    "Gastroenterology",
    "Hepatology",
    "Endocrinology",
    "Pediatrics",
    "Oncology",
    "Rheumatology",
    "Hematology",
    "Dermatology & Venereology",
    "Psychiatry",
    "Physical Medicine & Rehabilitation",
    "Aesthetic Dermatology",
    // Surgery
    "General Surgery",
    // "Cardiovascular Surgery",
    "Orthopedic Surgery",
    "Neurosurgery",
    "Gynecology & Obstetrics",
    "ENT & Head Neck Surgery",
    "Ophthalmology",
    "Oral & Dental Surgery",
    "Urology",
    "Colorectal Surgery",
    "Pediatric Surgery",
    "Vascular Surgery",
    // "Hepatobiliary Surgery",
]

export const genderData = [
    'Female',
    'Male',
    'Other',
]

export const onboardingStatusData = [
    'Draft',
    'On Hold',
    'Onboarded',
]

export const doctorTypeData = [
    'MBBS',
    'BDS',
]

export const appointmentTypeData = [
    'New',
    'Follow up',
]

export const paymentStatusData = [
    'Pending',
    'Paid',
    'Refunded',
]

export const doctorTitleData = [
    'Dr.',
    'Prof. Dr.',
]

export const doctorBanglaTitleData = [
    'ডাঃ',
    'প্রফেসর ডাঃ',
]

export const doctorDesignationData = [
   'Medical Officer',
   'Resident Medical Officer',
   'Registrar',
   'Assistant Registrar',
   'Chief Consultant',
   'Senior Consultant',
   'Consultant',
   'Associate Consultant',
   'Assistant Consultant',
   'Junior Consultant',
   'Head of Department',
   'Professor & Head',
   'Professor',
   'Associate Professor',
   'Assistant Professor',
   'Surgeon',
   'Associate Surgeon',
   'Assistant Surgeon',
   'Dental Surgeon',
   'Associate Dental Surgeon',
   'Assistant Dental Surgeon',
   'Chairman & Professor',
   'Chairman',
   'Director',
   'Deputy Director',
   'Deputy Director',
   'Deputy Director',
   'Assistant Director',
   'Managing Director',
   'Resident Physician',
   'Resident Surgeon',
   'Ex. Medical Officer',
   'Ex. Resident Medical Officer',
   'Ex. Registrar',
   'Ex. Assistant Registrar',
   'Ex. Chief Consultant',
   'Ex. Senior Consultant',
   'Ex. Consultant',
   'Ex. Associate Consultant',
   'Ex. Assistant Consultant',
   'Ex. Junior Consultant',
   'Ex. Head of Department',
   'Ex. Professor & Head',
   'Ex. Professor',
   'Ex. Associate Professor',
   'Ex. Assistant Professor',
   'Ex. Surgeon',
   'Ex. Associate Surgeon',
   'Ex. Assistant Surgeon',
   'Ex. Dental Surgeon',
   'Ex. Associate Dental Surgeon',
   'Ex. Assistant Dental Surgeon',
   'Ex. Chairman & Professor',
   'Ex. Chairman',
   'Ex. Director',
   'Ex. Deputy Director',
   'Ex. Deputy Director',
   'Ex. Deputy Director',
   'Ex. Assistant Director',
   'Ex. Managing Director',
   'Ex. Resident Physician',
   'Ex. Resident Surgeon',
]

export const mobileBankingOperatorData = [
    'bKash',
    'Rocket',
    'Nagad',
    'Upay',
]