import { useState, useEffect, forwardRef } from 'react';
import styled from "styled-components";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Table, TableHead, TableBody, TableCell, TableFooter,
         TablePagination, TableRow, IconButton, Tooltip } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import axios from "axios";
import { useSnackbar } from 'notistack';
import { FiFilter, FiCalendar, FiEdit, FiDownload } from 'react-icons/fi';
import moment from 'moment';

import { VBox, HBox } from './../../../components/Containers';
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import { Button } from '../../../components/Buttons';
import { InputText } from '../../../components/InputText';
import colors from "../../../config/colors";
import noImage from '../../../assets/images/noImage.png';
import SetProfileDlg from './SetProfileDlg';
import { MdEditNotifications } from 'react-icons/md';
import { BsFillEyeFill } from 'react-icons/bs';
import ViewDoctorDetailsDlg from './ViewDoctorDetailsDlg';
import AppointSlotsManagerDlg from './AppointSlotsManagerDlg';
import DoctorConsentFormPDF from '../../../layouts/DoctorConsentFormPDF';
import ConfirmEditRequestDlg from './ConfirmEditRequestDlg';
import DoctorFilterDlg from './DoctorFilterDlg';

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const colorProps = {
    'On Hold': '#ff6600',
    Onboarded: colors.green,
    Draft: colors.darkGrey,
};

const StatusChip = styled(HBox)`
    padding: 2px 5px;
    border-radius: 5px;
    color: white;
    background-color: ${props => colorProps[props.status || 'default']};
    max-width: max-content;
`
const Image = styled.img`
    height: 50px;
    width: 50px;
    border-radius: 25px;
    border: 1px solid ${colors.grey};
`

const LoadingTxt = styled(P3)`
    border: 1px solid ${colors.grey};
    border-radius: 3px;
    padding: 4px;
`

const FilterIcon = styled(FiFilter)`
    // size: 16px;
    color: ${colors.red};
`

const OnlineIcon = styled.div`
    background-color: ${colors.green};
    height: 8px;
    width: 8px;
    border-radius: 4px;
`

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        console.log('next button', count);
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <HBox style={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </HBox>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const DoctorTable = ({  }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [searchKey, setSearchKey] = useState("");
    const [doctors, setDoctors] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [count, setCount] = useState(null);
    const [inputTimeout, setInputTimeout] = useState(null);

    const [openAppointSlots, setOpenAppointSlots] = useState(false);
    const [openDocProfile, setOpenDocProfile] = useState(false);
    const [openDocDetails, setOpenDocDetails] = useState(false);
    const [openConsentForm, setOpenConsentForm] = useState(false);
    const [openEditRequestDlg, setOpenEditRequestDlg] = useState(false);
    const [openFilterBy, setOpenFilterBy] = useState(false);
    const [selectedDocId, setSelectedDocId] = useState(null);
    const [selectedDocName, setSelectedDocName] = useState("");
    const [selectedDocIndex, setSelectedDocIndex] = useState(null);

    const [ordering, setOrdering] = useState({orderBy: 'created_at', direction: '-'});
    const [filtering, setFiltering] = useState({
        gender: '', doctorType: '',
        specialty: '', onboardingStatus: '',
    });

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        enqueueSnackbar("Loading...", { persist: true });
        getData();
    }, []);
    useEffect(() => {
        getData();
    }, [page, rowsPerPage]);
    useEffect(() => {
        handleSearch();
    }, [searchKey]);
    useEffect(() => () => clearTimeout(inputTimeout), [inputTimeout]);
    useEffect(() => {
        reloadData();
    }, [ordering, filtering]);

    const getData = () => {
        setIsLoading(true);
        axios({
            method: 'GET',
            url: 'moderator/doctor-list/',
            params: {
                key: searchKey,
                gender: filtering.gender,
                doctor_type: filtering.doctorType,
                specialty: filtering.specialty,
                onboarding_status: filtering.onboardingStatus,
                order_by: ordering.orderBy,
                direction: ordering.direction,
                offset: page*rowsPerPage,
                limit: page*rowsPerPage+rowsPerPage
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            closeSnackbar();
            if (response.status === 200) {
                setDoctors(response.data.doctors);
                setCount(response.data.count);
            } else {
                console.log('DOCTOR LIST FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            closeSnackbar();
            console.log('DOCTOR LIST FETCH ERROR', error);
        })
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const handleSearch = () => {
        if (inputTimeout) clearTimeout(inputTimeout);
        setInputTimeout(
                setTimeout(() => {
                    setPage(0);
                    getData();
            }, 300)
        )
    }

    const reloadData = () => {
        setPage(0);
        getData();
    }

    const clearFilter = () => {
        setFiltering({
            gender: '', doctorType: '',
            specialty: '', onboardingStatus: '',
        });
    }

    const handleExportDoctorsExcel = () => {
        enqueueSnackbar('Preparing to download... Please wait a moment.', { persist: true });
        axios({
            method: 'GET',
            url: 'nhadmin/doctors-export-excel/',
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            closeSnackbar();
            if (response.status === 200) {
                const href = URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `doctors_${moment().format('YYYY-MM-DD')}.xlsx`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            } else {
                enqueueSnackbar('Something went wrong. Please try again.', {variant: 'error'});
                console.log('DOCTORS EXPORT TO EXCEL FAILED', response.status);
            }
        })
        .catch((error) => {
            closeSnackbar();
            enqueueSnackbar('Something went wrong. Please try again.', {variant: 'error'});
            console.log('DOCTORS EXPORT TO EXCEL FAILED', error);
        })
    }

    return (
        <>
        <HBox justify="space-between" align="center" className='mb-1'>
            <HBox align='center'>
                <H3 color='second' align='left'>Doctors Table</H3>
                <Button
                    className='ml-1'
                    size='xs'
                    color='first'
                    outlined
                    onClick={handleExportDoctorsExcel}
                    style={{ paddingLeft: 8, paddingRight: 8 }}
                >
                    <FiDownload className='mr-0_5' />
                    Excel
                </Button>
                {isLoading && <LoadingTxt className='ml-1'>Loading...</LoadingTxt>}
            </HBox>
            <HBox align='center'>
                <Tooltip title='Filtering' arrow>
                    <IconButton onClick={() => setOpenFilterBy(true)}>
                        <FilterIcon />
                    </IconButton>
                </Tooltip>
                <InputText
                    className='ml-1'
                    value={searchKey}
                    onChange={(e) => setSearchKey(e.target.value)}
                    placeholder="Search for a keyword..."
                />
            </HBox>
        </HBox>
        <VBox style={{ border: `1px solid ${colors.grey}`, borderRadius: 5 }}>
            <Table sx={{ minWidth: 500 }} size='small'>
                <TableHead>
                    <TableRow style={{ backgroundColor: colors.lightGrey }}>
                        <TableCell style={{ fontWeight: 'bold' }}>Photo</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>ID</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>BMDC#</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Doctor Name</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Specialty</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Phone</TableCell>
                        {/* <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell> */}
                        <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Completion</TableCell>
                        <TableCell className='bold'>Available Slots</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {doctors.map((doctor, index) => (
                        <TableRow key={doctor.id} style={{ height: 33 }}>
                            <TableCell>
                                <Image
                                    src={doctor.image ? `${baseURL}${doctor.image}` : noImage}
                                    // onError={({ currentTarget }) => {
                                    //     currentTarget.onerror = null;
                                    //     currentTarget.src = {noImage};
                                    // }}
                                />
                            </TableCell>
                            <TableCell>
                                {doctor.id}
                            </TableCell>
                            <TableCell>
                                {doctor.doctor_type === 'MBBS' ? 'A-' : ''}{doctor.bmdc}
                            </TableCell>
                            <TableCell className='bold'>
                                <HBox align='center'>
                                    <P2 className='bold'>{doctor.full_name}</P2>
                                    {doctor.is_online && <Tooltip title='Online' arrow><OnlineIcon className='ml-0_5' /></Tooltip>}
                                </HBox>
                            </TableCell>
                            <TableCell style={{ color: colors.red }}>
                                {doctor.specialty}
                            </TableCell>
                            <TableCell>
                                {doctor.phone}
                            </TableCell>
                            {/* <TableCell>
                                {doctor.email ? doctor.email : 'Not given'}
                            </TableCell> */}
                            <TableCell>
                                <StatusChip
                                    status={doctor.onboarding_status}
                                    className=''
                                >
                                    {doctor.onboarding_status}
                                </StatusChip>
                            </TableCell>
                            <TableCell>
                                <span style={{ color: doctor.profile_completion_percent === 100 ? colors.green : colors.red }}>{doctor.profile_completion_percent}%</span>
                            </TableCell>
                            <TableCell>
                                {doctor.available_slots_count}
                            </TableCell>
                            <TableCell style={{ width: 150 }}>
                                {
                                    doctor.onboarding_status === 'Draft' ? 
                                        <Tooltip title='Edit' arrow>
                                            <IconButton>
                                                <FiEdit
                                                    fontSize={16}
                                                    style={{ color: colors.blue }}
                                                    onClick={() => {setSelectedDocId(doctor.id);
                                                                    setSelectedDocName(doctor.full_name);
                                                                    setOpenDocProfile(true);
                                                                    setSelectedDocIndex(index)}}
                                                />
                                            </IconButton>
                                        </Tooltip> : <></>
                                }

                                {
                                    doctor.onboarding_status !== 'Draft' ?
                                        doctor.is_edit_requested ?
                                            <Tooltip title='Edit Requested'>
                                                <IconButton>
                                                    <MdEditNotifications 
                                                        fontSize={20}
                                                        style={{ color: colors.grey }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            :
                                            <Tooltip title='Edit Request' arrow>
                                                <IconButton>
                                                    <MdEditNotifications 
                                                        fontSize={20}
                                                        style={{ color: colors.green }}
                                                        onClick={() => {setSelectedDocId(doctor.id);
                                                                        setSelectedDocName(doctor.full_name);
                                                                        setOpenEditRequestDlg(true);
                                                                        setSelectedDocIndex(index)}}
                                                    />
                                                </IconButton>
                                            </Tooltip> : <></>
                                }
                                <Tooltip title='View Details' arrow>
                                    <IconButton>
                                        <BsFillEyeFill
                                            fontSize={20}
                                            style={{ color: colors.red }}
                                            onClick={() => {setSelectedDocId(doctor.id);
                                                            setSelectedDocName(doctor.full_name);
                                                            setOpenDocDetails(true)}}
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Manage Appointment Slots' arrow>
                                    <IconButton>
                                        <FiCalendar
                                            fontSize={16}
                                            style={{ color: colors.red }}
                                            onClick={() => {setSelectedDocIndex(index);
                                                            setOpenAppointSlots(true)}}
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Download Consent Form' arrow>
                                    <IconButton>
                                        <FiDownload
                                            fontSize={16}
                                            style={{ color: colors.red }}
                                            onClick={() => {setSelectedDocIndex(index);
                                                            setOpenConsentForm(true)}}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}

                    {emptyRows > 0 && (
                        <TableRow style={{ height: 33 * emptyRows }}>
                            <TableCell colSpan={10} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, 30]}
                            colSpan={10}
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </VBox>
        <SetProfileDlg 
            open={openDocProfile}
            setOpen={setOpenDocProfile}
            doctorId={selectedDocId}
            doctorName={selectedDocName}
            setDoctors={setDoctors}
            doctors={doctors}
            doctorIndex={selectedDocIndex}

        />
        <ViewDoctorDetailsDlg 
            open={openDocDetails}
            setOpen={setOpenDocDetails}
            doctorId={selectedDocId}
            doctorName={selectedDocName}

        />
        <AppointSlotsManagerDlg
            open={openAppointSlots}
            setOpen={setOpenAppointSlots}
            doctorId={doctors[selectedDocIndex]?.id}
            doctorName={doctors[selectedDocIndex]?.full_name}
        />
        <DoctorConsentFormPDF
            open={openConsentForm}
            setOpen={setOpenConsentForm}
            doctorId={doctors[selectedDocIndex]?.id}
        />
        <ConfirmEditRequestDlg 
            open={openEditRequestDlg}
            setOpen={setOpenEditRequestDlg}
            doctorId={selectedDocId}
            doctorName={selectedDocName}
            doctors={doctors}
            setDoctors={setDoctors}
            doctorIndex={selectedDocIndex}
        />
        <DoctorFilterDlg
            open={openFilterBy}
            setOpen={setOpenFilterBy}
            ordering={ordering}
            setOrdering={setOrdering}
            filtering={filtering}
            setFiltering={setFiltering}
            clearFilter={clearFilter}
        />
        </>
    );
}

export default DoctorTable;