import { useState, useEffect } from "react";
import styled from "styled-components";
import { FiLogOut } from "react-icons/fi";
import { MdOutlineDashboard } from "react-icons/md";
import { FiUser, FiDatabase, FiCalendar } from "react-icons/fi";
import { IoPeopleOutline, IoAddOutline } from "react-icons/io5";
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { MdOutlineNotificationsNone } from "react-icons/md";
import axios from "axios";
import { useSnackbar } from "notistack";

import { HBox, VBox } from "../components/Containers";
import { H3, P1, P2, P3 } from "../components/Typography";
import colors from "../config/colors";
import { logout } from "../services/actions/authAction";
import { removeHttp } from "../utils";
import NotificationsDlg from "./NotificationsDlg";
import myHealthLogo from '../assets/images/logo_main.png';

/* ************************ Upper nav *********************** */
const LogoutBtn = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    /* text-decoration: none; */
`

const NavbarUp = styled.div`
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    width: 100%;
    height: 50px;
    display: flex;
    background-color: ${colors.lightGrey};
    box-shadow: 0px 2px 5px 0px ${colors.shadow};
    align-items: center;
    justify-content: space-between;
`

const NotificationIcon = styled(MdOutlineNotificationsNone)`
    font-size: 24px;
    color: ${colors.darkGrey};
`

const NotifCount = styled(P3)`
    position: relative;
    top: -8px;
    left: -5px;
    border-radius: 10px;
    background-color: ${colors.blue};
    color: white;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 2px;
`

const NotificationBadge = ({ count, setCount, className }) => {
    const [openNotif, setOpenNotif] = useState(false);

    return (
        <>
        <HBox className={className} align='center'>
            <NotificationIcon className="clickable" onClick={() => setOpenNotif(true)} />
            {count ? <NotifCount>
                {count}
            </NotifCount> : null}
        </HBox>

        <NotificationsDlg
            open={openNotif}
            setOpen={setOpenNotif}
            setNotifCount={setCount}
        />
        </>
    )
}

let callSocket;

const _UpperNav = ({ logout, isAuthenticated }) => {
    const navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // const [userId, setUserId] = useState("");
    const [newNotification, setNewNotification] = useState(null);
    const [notifCount, setNotifCount] = useState(null);

    useEffect(() => {
        if (isAuthenticated) getUserData();
    }, [isAuthenticated]);
    useEffect(() => {
        enqueueSnackbar(newNotification?.text);
    }, [newNotification]);

    function getUserData() {
        axios({
            method: 'GET',
            url: 'core/user-details/',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        }).then(res => {
            if (res.status === 200) {
                // setUserId(res.data.id);
                setNotifCount(res.data.unseen_notif_count);
                connectSocket(res.data.id);
            }
            else {
                console.log('USER DATA FETCH FAILED');
            }
        }).catch(error => {
            console.log('USER DATA FETCH ERROR', error);
        });
    }

    function connectSocket(id) {
        let ws_scheme = window.location.protocol == "https:" ? "wss://" : "ws://";
        
        callSocket = new WebSocket(
            ws_scheme
            + removeHttp(process.env.REACT_APP_SERVER_URL)
            // + window.location.host
            + 'ws/chat/'
        );
        
        callSocket.onopen = event => {
            callSocket.send(JSON.stringify({
                type: 'login',
                data: {
                    id: id
                }
            }));
        }
    
        callSocket.onmessage = (e) => {
            let response = JSON.parse(e.data);
            let type = response.type;

            if(type === 'connection') {
                console.log(response.data.message);

            }
            else if (type === 'notification_received') {
                onNewNotification(response.data);
            }
        }

        const onNewNotification = (data) => {
            setNewNotification(data);
            setNotifCount(data.unseen_count);
        }
    }

    const handleLogout = () => {
        logout();
        navigate('/login');
    }

    return (
        <NavbarUp>
            <HBox align="center">
                <img
                    style={{ height: 45 }}
                    className="ml-1 clickable"
                    src={myHealthLogo}
                    alt="logo"
                    onClick={() => {window.open(process.env.REACT_APP_MYHEALTH_WEBSITE_URL)}}
                />
                {/* <H3 className='ml-2'>NextGen MyHealth</H3> */}
                <P2 className="ml-1" color='second'>Moderator Portal</P2>
            </HBox>
            <HBox align='center'>
                <NotificationBadge count={notifCount} setCount={setNotifCount} className="mr-2" />
                <LogoutBtn onClick={handleLogout}>
                    <FiLogOut />
                    <P1 className='mr-2 ml-1'>Logout</P1>
                </LogoutBtn>
            </HBox>
        </NavbarUp>
    )
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export const UpperNav = connect(mapStateToProps, { logout })(_UpperNav);
/* ****************** End upper nav ************************ */

/* ******************** Lower nav ************************** */
const LowerNavContainer = styled.div`
    position: sticky;
    position: -webkit-sticky;
    top: 50px;
    display: flex;
    justify-content: center;
    z-index: 1;
`

const NavbarLow = styled.div`
    width: 60%;
    height: 60px;
    display: flex;
    background-color: ${colors.blue};
    box-shadow: 0px 2px 5px 0px ${colors.shadow};
    align-items: stretch;
    justify-content: space-around;
`

const SNavLink = styled(NavLink)`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-decoration: none;
    cursor: pointer;
    :hover {
        border-bottom: 3px solid white;
    }
    /* :active {  // Not working
        border-bottom: 3px solid white;
    } */
`

export const LowerNav = ({ selected }) => {
    return (
        <LowerNavContainer>
            <NavbarLow>
                {/* <SNavLink to='/dashboard' style={{ borderBottom: selected==='dashboard' ? '3px solid white' : "" }}>
                    <MdOutlineDashboard fontSize='1.5rem' />
                    <P2 color='white'>Dashboard</P2>
                </SNavLink> */}
                <SNavLink to='/appointments' style={{ borderBottom: selected==='appointments' ? '3px solid white' : "" }}>
                    <FiCalendar fontSize='1.5rem' />
                    <P2 color='white'>Appointments</P2>
                </SNavLink>
                <SNavLink to='/doctors' style={{ borderBottom: selected==='doctors' ? '3px solid white' : "" }}>
                    <IoPeopleOutline fontSize='1.5rem' />
                    <P2 color='white'>Doctors</P2>
                </SNavLink>
                <SNavLink to='/patients' style={{ borderBottom: selected==='patients' ? '3px solid white' : "" }}>
                    <IoPeopleOutline fontSize='1.5rem' />
                    <P2 color='white'>Patients</P2>
                </SNavLink>
                <SNavLink to='/add-doctors' style={{ borderBottom: selected==='add' ? '3px solid white' : "" }}>
                    <IoAddOutline fontSize='1.5rem' />
                    <P2 color='white'>Add Doctors</P2>
                </SNavLink>
                {/* <SNavLink to="#" style={{ borderBottom: selected==='profile' ? '3px solid white' : "" }}>
                    <FiUser fontSize='1.5rem' />
                    <P2 color='white'>Profile</P2>
                </SNavLink> */}
            </NavbarLow>
        </LowerNavContainer>
    )
}
/* ****************** End lower nav *************************** */

// const NavContainer = styled.div`
//     position: sticky;
//     position: -webkit-sticky;
//     top: 0px;
//     z-index: 1;
// `

// const Navbar = ({ selected }) => {
//     return (
//         <NavContainer>
//             <UpperNav />
//             <LowerNav selected={selected} />
//         </NavContainer>
//     )
// }

// export default Navbar;