import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FiEdit3, FiCheck, FiX, FiPlusSquare, FiTrash } from "react-icons/fi";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import { useSnackbar } from "notistack";
import { Dialog, Tooltip } from "@mui/material";
import { FaCamera } from "react-icons/fa";
import { InputText, InputTextarea } from "../../../components/InputText";
import { Button, NavBtnActive, NavBtn, IconButton } from "../../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import { VBox, HBox, LeftPanel, CenterPanel, RightPanel } from "../../../components/Containers";
import colors from "../../../config/colors";
import responsive from "../../../config/responsive";
import PictureUploadDlg  from '../../Doctors/components/PictureUploadDlg';
import { IoMdAddCircle } from 'react-icons/io';
import { HiMinusCircle } from 'react-icons/hi';
import { specialtiesData, doctorTitleData, doctorBanglaTitleData,
         doctorDesignationData, mobileBankingOperatorData } from '../../../data';

const Container = styled(HBox)``;

const AddPhoto = styled(FaCamera)`
    color: ${colors.red};
    font-size: 25px;
    cursor: pointer;
    margin-left: 8px;
    :hover {
        color: ${colors.darkRed};
    }
`

const TopPanel = styled(HBox)`
    width: 100%;
    background-color: ${colors.lightGrey};
    border-bottom: 1px solid ${colors.grey};
`

const Paper = styled.div`
    width: 100%;
    border-radius: 10px;
`

const Input = styled.input`
    border: 1px solid ${colors.grey};
    color: ${colors.darkGrey};
    border: none;
    border-bottom: 1px solid ${colors.grey};
    
    :focus {
        color: black;
        outline: none;
    }
`

const Select = styled.select`
    color: ${colors.darkGrey};
    border: none;
    border-bottom: 1px solid ${colors.grey};

    :focus {
        color: black;
        outline: none;
    }
`

const EditIcon = styled(FiEdit3)`
    color: ${colors.blue};
    cursor: pointer;
`

const CheckIcon = styled(FiCheck)`
    color: ${colors.green};
    cursor: pointer;
`

const XIcon = styled(FiX)`
    color: ${colors.red};
    cursor: pointer;
`

const AddIcon = styled(FiPlusSquare)`
    color: ${colors.blue};
    cursor: pointer;
`

const DeleteIcon = styled(FiTrash)`
    color: ${colors.red};
    cursor: pointer;
`

const SLink = styled(Link)`
    text-decoration: none;
`

const HighlightChip = styled(HBox)`
    background-color: ${colors.green};
    border: 1px solid ${colors.green};
    border-radius: 2px;
`

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const SpecialtiesHtml = ({ specialtyValues, setSpecialtyValues }) => {
    const handleSpecialtyChange = (index, event) => {
        let array = [...specialtyValues];
        array[index][event.target.name] = event.target.value;
        setSpecialtyValues(array);
    }
    const onDelete = (index) => {
        let array = [...specialtyValues];
        array.splice(index, 1);
        setSpecialtyValues(array)
    }
    return (
        <>
            {
                specialtyValues.map((specialty, index) => (
                    <HBox key={index} align='center' className='mt-2 ml-1'>
                        <InputText 
                            list='specialty-list'
                            label='Specialty' 
                            style={{width: '50%'}} 
                            value={specialty.specialty} 
                            name='specialty' 
                            onChange={(event) => handleSpecialtyChange(index, event)}
                        />
                        <datalist id='specialty-list'>
                            {
                                specialtiesData.map((specialty, index) => {
                                    return <option key={index}>{specialty}</option>
                                })
                            }
                        </datalist>
                        {
                            index ?
                            <HiMinusCircle 
                                style={{ 
                                    fontSize: '24px', 
                                    margin: '6px', 
                                    color: colors.red, 
                                    cursor: 'pointer'
                                }}
                                onClick={() => onDelete(index)}
                            />
                        :
                            <IoMdAddCircle 
                                style={{ fontSize: '24px', margin: '6px', color: colors.green, cursor: 'pointer'}}
                                onClick={() => setSpecialtyValues([...specialtyValues, {specialty: ''}])}
                            />
                        }
                    </HBox>
                ))
            }
        </>
    )
}

const AffiliationsHtml = ({ affiliationValues, setAffiliationValues }) => {
    const [suggestedInstitutions, setSuggestedInstitutions] = useState([]);

    const handleAffiliationChange = (index, event) => {
        let array = [...affiliationValues];
        if (event.target.type === 'checkbox') {
            array[index][event.target.name] = event.target.checked;
        } else {
            array[index][event.target.name] = event.target.value;

            if (event.target.name === 'institution') getSuggestedInstutions(event.target.value);
        }
        setAffiliationValues(array);
    }
    const onDelete = (index) => {
        let array = [...affiliationValues];
        array.splice(index, 1);
        setAffiliationValues(array)
    }

    const getSuggestedInstutions = (text) => {
        axios({
            method: 'GET',
            url: `nhadmin/suggested-institution-list/`,
            params: {
                key: text
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            if (response.status === 200) {
                setSuggestedInstitutions(response.data);
            } else {
                console.log('SUGGESTED INSTITUTIONS GET FAILED', response.status);
            }
        })
        .catch((error) => {
            console.log('SUGGESTED INSTITUTIONS GET ERROR', error);
        })
    }

    return (
        <>
            {
                affiliationValues.map((affiliation, index) => (
                    <Paper key={index} className='p-3'>
                        <HBox align='center'>
                            {
                                index ?
                                <>
                                    <H3>Affiliations - {index}</H3>
                                    <HiMinusCircle 
                                        style={{ 
                                            fontSize: '24px', 
                                            margin: '6px', 
                                            color: colors.red, 
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => onDelete(index)}
                                    />
                                </>
                                :
                                <>
                                    <H3>Affiliations</H3>
                                    <IoMdAddCircle 
                                        style={{ fontSize: '24px', margin: '6px', color: colors.green, cursor: 'pointer'}}
                                        onClick={() => setAffiliationValues([...affiliationValues, {institution: '', designation: '', department: '', currently_working: false, show_in_summary: false}])}
                                    />
                                </>
                            }
                        </HBox>
                        <HBox align='center' className='mt-2 ml-1'>
                            <InputText 
                                label='Institution/Hospital' 
                                style={{width: '100%'}} 
                                name='institution'
                                list='institutions'
                                value={affiliation.institution}
                                onChange={(event) => handleAffiliationChange(index, event)}
                            />
                        </HBox>
                        <datalist id='institutions'>
                            {suggestedInstitutions.map((inst, index) => {
                                return <option
                                            key={inst.id}
                                            // value={inst.name}
                                        >{inst.name}</option>
                            })}
                        </datalist>
                        <HBox align='center' className='mt-2 ml-1'>
                            <InputText 
                                label='Designation'
                                list='designation'
                                style={{width: '100%'}} 
                                name='designation' 
                                value={affiliation.designation}
                                onChange={(event) => handleAffiliationChange(index, event)}
                            />

                            <datalist id='designation'>
                                {doctorDesignationData.map((designation) => (
                                    <option>{designation}</option>
                                ))}
                            </datalist>
                        </HBox>
                        <HBox align='center' className='mt-2 ml-1'>
                            <InputText 
                                label='Department'
                                list='department'
                                style={{width: '100%'}} 
                                name='department'
                                value={affiliation.department}
                                onChange={(event) => handleAffiliationChange(index, event)}
                            />

                            <datalist id='department'>
                                {specialtiesData.map((specialty) => (
                                    <option>Dept. of {specialty}</option>
                                ))}
                            </datalist>
                        </HBox>
                        <HBox align='center' className='mt-2'>
                            <input 
                                className='ml-1 mr-1' 
                                type='checkbox' 
                                name='currently_working'
                                checked={affiliation.currently_working}
                                onChange={(event) => handleAffiliationChange(index, event)}
                            /> Present
                        </HBox>
                        <HBox align='center' className='mt-1'>
                            <input 
                                className='ml-1 mr-1' 
                                type='checkbox' 
                                name='show_in_summary'
                                checked={affiliation.show_in_summary}
                                onChange={(event) => handleAffiliationChange(index, event)}
                            /> Highlight in doctor's profile
                        </HBox>
                    </Paper>
                ))
            }
        </>
    )
}

const ChambersHtml = ({ chamberValues, setChamberValues }) => {
    const handleChamberChange = (index, event) => {
        let array = [...chamberValues];
        array[index][event.target.name] = event.target.value;
        setChamberValues(array);
    }
    const onDelete = (index) => {
        let array = [...chamberValues];
        array.splice(index, 1);
        setChamberValues(array)
    }
    return (
        <>
            {
                chamberValues.map((chamber, index) => (
                    <HBox key={index} align='center' className='mt-2 ml-1'>
                        <InputText 
                            label='Chamber' 
                            style={{width: '50%'}}
                            value={chamber.address}
                            name='address'
                            onChange={(event) => handleChamberChange(index, event)}
                        />
                        <HiMinusCircle 
                            style={{ 
                                fontSize: '24px', 
                                margin: '6px', 
                                color: colors.red, 
                                cursor: 'pointer'
                            }}
                            onClick={() => onDelete(index)}
                        />
                    </HBox>
                ))
            }
        </>
    )
}

const ConsultationTimeHtml = ({ consultationTimeValues, setConsultationTimeValues}) => {
    const handleConsultationTimeChange = (index, event) => {
        let array = [...consultationTimeValues];
        if (event.target.type === 'checkbox') {
            array[index]['schedule'][event.target.name] = event.target.checked;
        } else {
            array[index]['schedule'][event.target.name] = event.target.value;
        }
        setConsultationTimeValues(array);
    }
    const onDelete = (index) => {
        let array = [...consultationTimeValues];
        array.splice(index, 1);
        setConsultationTimeValues(array)
    }
    return (
        <>
            {
                consultationTimeValues.map((consultationTime, index) => (
                    <Paper key={index} className='p-3'>
                        <HBox align='center' >
                            {
                                index ?
                                <>
                                <H3>Consultation Time - {index}</H3>
                                <HiMinusCircle 
                                    style={{ 
                                        fontSize: '24px', 
                                        margin: '6px', 
                                        color: colors.red, 
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => onDelete(index)}
                                />
                                </>
                                :
                                <>
                                <H3>Consultation Time</H3>
                                <IoMdAddCircle
                                    style={{ fontSize: '24px', margin: '6px', color: colors.green, cursor: 'pointer'}}
                                    onClick={() => setConsultationTimeValues([...consultationTimeValues, {id: null, schedule: {
                                        startTime: '',
                                        endTime: '',
                                        friday: false,
                                        saturday: false,
                                        sunday: false,
                                        monday: false,
                                        tuesday: false,
                                        wednesday: false,
                                        thursday: false
                                    }}])}
                                />
                                </>
                            }
                        </HBox>
                        
                        <HBox className='ml-1' justify='space-between'>
                            <HBox>
                                <input 
                                    className='mr-1' 
                                    type='checkbox' 
                                    name='friday'
                                    checked={consultationTime.schedule.friday}
                                    onChange={(event) => handleConsultationTimeChange(index, event)}
                                /> Friday
                            </HBox>
                            <HBox>
                                <input 
                                    className='mr-1' 
                                    type='checkbox'
                                    name='saturday' 
                                    checked={consultationTime.schedule.saturday}
                                    onChange={(event) => handleConsultationTimeChange(index, event)}
                                /> Saturday
                            </HBox>
                            <HBox>
                                <input 
                                    className='mr-1' 
                                    type='checkbox'
                                    name='sunday'
                                    checked={consultationTime.schedule.sunday}
                                    onChange={(event) => handleConsultationTimeChange(index, event)} 
                                /> Sunday
                            </HBox>
                            <HBox>
                                <input 
                                    className='mr-1' 
                                    type='checkbox'
                                    name='monday' 
                                    checked={consultationTime.schedule.monday}
                                    onChange={(event) => handleConsultationTimeChange(index, event)}
                                /> Monday
                            </HBox>
                            <HBox>
                                <input 
                                    className='mr-1' 
                                    type='checkbox' 
                                    name='tuesday'
                                    checked={consultationTime.schedule.tuesday}
                                    onChange={(event) => handleConsultationTimeChange(index, event)}
                                /> Tuesday
                            </HBox>
                            <HBox>
                                <input 
                                    className='mr-1' 
                                    type='checkbox' 
                                    name='wednesday'
                                    checked={consultationTime.schedule.wednesday}
                                    onChange={(event) => handleConsultationTimeChange(index, event)}
                                /> Wednesday
                            </HBox>
                            <HBox>
                                <input 
                                    className='mr-1' 
                                    type='checkbox' 
                                    name='thursday'
                                    checked={consultationTime.schedule.thursday}
                                    onChange={(event) => handleConsultationTimeChange(index, event)}
                                /> Thursday
                            </HBox>
                        </HBox>
                        
                        <HBox align='center' className='mt-2 ml-1'>
                            <InputText 
                                type='time'
                                label='Start Time' 
                                name='startTime'
                                value={consultationTime.schedule.startTime}
                                style={{ width: 'calc(50% - 2px)' }}
                                className="mr-0_5"
                                onChange={(event) => handleConsultationTimeChange(index, event)}
                            />
                            <InputText 
                                type='time'
                                label='End Time' 
                                name='endTime'
                                value={consultationTime.schedule.endTime}
                                style={{ width: 'calc(50% - 2px)' }}
                                onChange={(event) => handleConsultationTimeChange(index, event)}
                            />
                        </HBox>
                    </Paper>
                ))
            }
        </>
    )
}

const SetProfileDlg = ({ open, setOpen, doctorId, doctorName, setDoctors, doctors, doctorIndex }) => {
    const phonePrefix = '+88';
    const [isLoading, setIsLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const [uploadPicOpen, setUploadPicOpen] = useState(false);
    const [uploadSignOpen, setUploadSignOpen] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [prevImage, setPrevImage] = useState('');  // To show in GUI
    const [image, setImage] = useState('');
    const [sign, setSign] = useState('');
    const [prevSign, setPrevSign] = useState('');  // To show in GUI
    const [doctorType, setDoctorType] = useState('');
    const [title, setTitle] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [banglaTitle, setBanglaTitle] = useState('');
    const [fullNameBangla, setFullNameBangla] = useState('');
    const [gender, setGender] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [assistantPhone, setAssistantPhone] = useState('');
    const [contactPreference, setContactPreference] = useState({ personal: false, assistant: false });
    const [email, setEmail] = useState('');
    const [bmdc, setBmdc] = useState('');
    const [qualification, setQualification] = useState('');
    const [trainings, setTrainings] = useState('');
    const [experience, setExperience] = useState('');
    const [specialtyValues, setSpecialtyValues] = useState([{specialty: ''}]);
    const [affiliationValues, setAffiliationValues] = useState([{institution: '', designation: '', department: '', currently_working: false, show_in_summary: false}]);
    const [chamberValues, setChamberValues] = useState([{address: ''}]);
    const [consultationTimeValues, setConsultationTimeValues] = useState([{id: null, schedule: {
        startTime: '',
        endTime: '',
        friday: false,
        saturday: false,
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false
    }}]);
    const [regularFee, setRegularFee] = useState('');
    const [followUpFee, setFollowUpFee] = useState('');
    const [followUpDuration, setFollowUpDuration] = useState('');
    const [accountType, setAccountType] = useState({ savings: false, current: false });
    const [accountName, setAccountName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [bankName, setBankName] = useState('');
    const [branchName, setBranchName] = useState('');
    const [relToAccHolder, setRelToAccHolder] = useState('');
    const [mobileBankingNumber, setMobileBankingNumber] = useState('');
    const [operator, setOperator] = useState('');
    const [altMobileBankingNumber, setAltMobileBankingNumber] = useState('');
    const [altOperator, setAltOperator] = useState('');

    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setDoctorType('')
        setImage('')
        setSign('')
        setTitle('')
        setFirstName('')
        setLastName('')
        setBanglaTitle('')
        setFullNameBangla('')
        setGender('')
        setAddress('')
        setPhone('')
        setAssistantPhone('')
        setContactPreference({ personal: false, assistant: false })
        setEmail('')
        setBmdc('')
        setQualification('')
        setTrainings('')
        setExperience('')
        setSpecialtyValues([{specialty: ''}])
        setAffiliationValues([{institution: '', designation: '', department: '', currently_working: false, show_in_summary: false}])
        setChamberValues([{address: ''}])
        setConsultationTimeValues([{id: null, schedule: {
            startTime: '',
            endTime: '',
            friday: false,
            saturday: false,
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false
        }}])
        setRegularFee('')
        setFollowUpFee('')
        setFollowUpDuration('')
        setAccountType({ savings: false, current: false })
        setAccountName('')
        setAccountNumber('')
        setBankName('')
        setBranchName('')
        setRelToAccHolder('')
        setMobileBankingNumber('')
        setOperator('')
        setAltMobileBankingNumber('')
        setAltOperator('')
        setShowError(false)
        setErrorMessage('')
        closeSnackbar();
    }, [open]);

    const validate = () => {
        let validated = true;
        let erMsg = '';

        if(!doctorType) {
            validated = false;
            erMsg = 'Doctor type can not be kept blank.';
        }

        if(!title) {
            validated = false;
            erMsg = 'Title can not be kept blank.';
        }

        if (!firstName) {
            validated = false;
            erMsg = 'First Name can not be kept blank.';
        }

        if (!lastName) {
            validated = false;
            erMsg = 'Last Name can not be kept blank.';
        }

        if (!phone) {
            validated = false;
            erMsg = 'Phone Number can not be kept blank.';
        }
        else if (phone.match(/^01\d{9}$/) === null) {
            validated = false;
            erMsg = "Please enter a valid phone number.";
        }

        if (assistantPhone) {
            if (assistantPhone.match(/^01\d{9}$/) === null) {
                validated = false;
                erMsg = "Please enter a valid assistant phone number.";
            }
        }

        if (email) {
            if (!(email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) || email === "")) {
                validated = false;
                erMsg = "Please put a valid email address or clear if not necessary.";
            }
        }

        if (bmdc.trim() === "") {
            validated = false;
            erMsg = "BMDC can not be kept blank.";
        }
        else if (bmdc.includes('A-')) {
            validated = false;
            erMsg = "Please exclude 'A-' from BMDC.";
        }
        else if (bmdc.match(/^[0-9\b]+$/) === null) {
            validated = false;
            erMsg = "Please put only numeric digits in BMDC. Ex: 1234.";
        }

        if (!regularFee) {
            validated = false;
            erMsg = 'Regular Fee can not be kept blank.';
        }


        if (!followUpFee) {
            validated = false;
            erMsg = 'Follow Up Fee can not be kept blank.';
        }

        if (!validated) {
            setShowError(true);
            setErrorMessage(erMsg);
            enqueueSnackbar(erMsg, { variant : 'error' });
        }
        
        return validated;
    }

    const handleChangeDoctorType = (event) => {
        setDoctorType(event.target.value)
    }
    
    const handleChangeTitle = (event) => {
        setTitle(event.target.value)
    }

    const handleChangeFirstName = (event) => {
        setFirstName(event.target.value)
    }

    const handleChangeLastName = (event) => {
        setLastName(event.target.value)
    }

    const handleChangeBanglaTitle = (event) => {
        setBanglaTitle(event.target.value)
    }

    const handleChangeFullNameBangla = (event) => {
        setFullNameBangla(event.target.value)
    }

    const handleChangeGender = (event) => {
        setGender(event.target.value)
    }

    const handleChangeAddress = (event) => {
        setAddress(event.target.value)
    }

    const handleChangePhone = (event) => {
        setPhone(event.target.value)
    }

    const handleChangeAssistantPhone = (event) => {
        setAssistantPhone(event.target.value);
    }

    const handleChangeContactPref = (event) => {
        setContactPreference({personal: false, assistant: false, [event.target.name]: event.target.checked});
    }

    const handleChangeEmail = (event) => {
        setEmail(event.target.value)
    }

    const handleChangeQualification = (event) => {
        setQualification(event.target.value)
    }

    const handleChangeTrainings = (event) => {
        setTrainings(event.target.value)
    }

    const handleChangeBmdc= (event) => {
        setBmdc(event.target.value)
    }

    const handleChangeExperience = (event) => {
        setExperience(event.target.value)
    }

    const handleChangeRegularFee = (event) => {
        setRegularFee(event.target.value)
    }

    const handleChangeFollowupFee = (event) => {
        setFollowUpFee(event.target.value)
    }

    const handleChangeFollowupDuration = (event) => {
        setFollowUpDuration(event.target.value)
    }

    const handleChangeAccountType = (event) => {
        setAccountType({savings: false, current: false, [event.target.name]: event.target.checked});
    }

    const handleChangeAccountName = (event) => {
        setAccountName(event.target.value)
    }

    const handleChangeAccountNumber = (event) => {
        setAccountNumber(event.target.value)
    }

    const handleChangeBankName = (event) => {
        setBankName(event.target.value)
    }

    const handleChangeBranchName = (event) => {
        setBranchName(event.target.value)
    }

    const handleChangeRelToAccHolder = (event) => {
        setRelToAccHolder(event.target.value);
    }

    const handleChangeMobileBankingNumber = (event) => {
        setMobileBankingNumber(event.target.value)
    }

    const handleChangeOperator = (event) => {
        setOperator(event.target.value)
    }

    const handleChangeAltMobileBankingNumber = (event) => {
        setAltMobileBankingNumber(event.target.value)
    }

    const handleChangeAltOperator = (event) => {
        setAltOperator(event.target.value)
    }

    const handleAddPhoto = (blob) => {
        setImage(blob)
    }

    const handleAddSign = (blob) => {
        setSign(blob)
    }

    const submitForm = (status) => {
        var formData = new FormData();
        formData.append('image', image);
        formData.append('sign', sign);
        formData.append('doctor_type', doctorType);
        formData.append('title', title);
        formData.append('first_name', firstName);
        formData.append('last_name', lastName);
        formData.append('bangla_title', banglaTitle);
        formData.append('bangla_name', fullNameBangla);
        formData.append('gender', gender);
        formData.append('address', address);
        formData.append('phone', phonePrefix+phone);
        formData.append('assistant_phone', assistantPhone);
        formData.append('contact_preference', getContactPrefVal());
        formData.append('email', email);
        formData.append('bmdc', bmdc);
        formData.append('qualification', qualification);
        formData.append('trainings', trainings);
        formData.append('experience', experience);
        formData.append('specialty', JSON.stringify(specialtyValues));
        formData.append('affiliation', JSON.stringify(affiliationValues));
        formData.append('chamber', JSON.stringify(chamberValues));
        formData.append('consultation_time', JSON.stringify(consultationTimeValues));
        formData.append('regular_fee', regularFee);
        formData.append('followup_fee', followUpFee);
        formData.append('followup_duration', followUpDuration);
        formData.append('account_type', getAccountTypeVal());
        formData.append('account_name', accountName);
        formData.append('account_number', accountNumber);
        formData.append('bank_name', bankName);
        formData.append('branch_name', branchName);
        formData.append('relation_to_acc_holder', relToAccHolder);
        formData.append('mobile_banking_number', mobileBankingNumber);
        formData.append('mobile_banking_operator', operator);
        formData.append('alt_mobile_banking_number', altMobileBankingNumber);
        formData.append('alt_mobile_banking_operator', altOperator);
        formData.append('onboarding_status', status);
        formData.append('doctor_id', doctorId);

        if (validate()) {
            enqueueSnackbar('Submitting...', { persist: true })
            axios({
                method: "PUT",
                url: 'moderator/doctor-detail/',
                data: formData,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('nh-access')}`,
                },
            })
            .then((response) => {
                closeSnackbar();
                if (response.status === 200) {
                    if (status === 'On Hold') enqueueSnackbar('Doctor onboarding request successfull.', { variant: 'success' })
                    else if (status === 'Draft') enqueueSnackbar('Doctor info updated.', { variant: 'success' })
                    setOpen(false)
                    
                    setDoctorType('');
                    setImage('')
                    setSign('')
                    setBmdc('')
                    setTitle('')
                    setFirstName('')
                    setLastName('')
                    setBanglaTitle('')
                    setFullNameBangla('')
                    setGender('')
                    setAddress('')
                    setPhone('')
                    setAssistantPhone('')
                    setContactPreference({ personal: false, assistant: false })
                    setEmail('')
                    setBmdc('')
                    setQualification('')
                    setTrainings('')
                    setExperience('')
                    setSpecialtyValues([{specialty: ''}])
                    setAffiliationValues([{institution: '', designation: '', department: '', currently_working: false, show_in_summary: false}])
                    setChamberValues([{address: ''}])
                    setConsultationTimeValues([{id: null, schedule: {
                        startTime: '',
                        endTime: '',
                        friday: false,
                        saturday: false,
                        sunday: false,
                        monday: false,
                        tuesday: false,
                        wednesday: false,
                        thursday: false
                    }}])
                    setRegularFee('')
                    setFollowUpFee('')
                    setFollowUpDuration('')
                    setAccountType({ savings: false, current: false })
                    setAccountName('')
                    setAccountNumber('')
                    setBankName('')
                    setBranchName('')
                    setRelToAccHolder('')
                    setMobileBankingNumber('')
                    setOperator('')
                    setAltMobileBankingNumber('')
                    setAltOperator('')

                    let values = [...doctors]
                    values[doctorIndex].onboarding_status = response.data.onboarding_status
                    setDoctors(values)

                }
            })
            .catch((error) => {
                closeSnackbar();
                console.log('DOCTOR UPDATE ERROR', error);
                if (error.request.status === 409) {
                    var errorJson = JSON.parse(error.request.responseText);
                    enqueueSnackbar(errorJson.error, { variant: 'error' })
                }
            })
        }
    }

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {
        if (open) {
            enqueueSnackbar("Loading...", { persist: true });
            getData();
        }
    }, [open, doctorId]);

    const getData = async () => {
        setIsLoading(true);
        await axios({
            method: 'GET',
            url: `moderator/doctor-detail/`,
            params: {
                doctor_id: doctorId,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            closeSnackbar();
            if (response.status === 200) {
                setPrevImage(response.data.image)
                setPrevSign(response.data.sign)
                setDoctorType(response.data.doctor_type ? response.data.doctor_type : '')
                setTitle(response.data.title ? response.data.title : '')
                setFirstName(response.data.first_name ? response.data.first_name : '')
                setLastName(response.data.last_name ? response.data.last_name : '')
                setBanglaTitle(response.data.bangla_title ? response.data.bangla_title : '')
                setFullNameBangla(response.data.bangla_name ? response.data.bangla_name : '')
                setGender(response.data.gender ? response.data.gender : '')
                setAddress(response.data.address ? response.data.address : '')
                setPhone(response.data.phone ? response.data.phone.replace(phonePrefix, '') : '')
                setAssistantPhone(response.data.assistant_phone ? response.data.assistant_phone : '')
                setContactPrefVal(response.data.contact_preference)
                setEmail(response.data.email ? response.data.email : '')
                setBmdc(response.data.bmdc ? response.data.bmdc : '')
                setQualification(response.data.qualification ? response.data.qualification : '')
                setTrainings(response.data.trainings ? response.data.trainings : '')
                setExperience(response.data.experience ? response.data.experience : '')
                if (Array.isArray(response.data.specialty) && response.data.specialty.length > 0) {
                    setSpecialtyValues(
                        response.data.specialty.map(value => ({['specialty']: value}))
                    )
                } else if (response.data.specialty.length > 0) {
                    setSpecialtyValues([{specialty: response.data.specialty}])
                } else {
                    setSpecialtyValues([{specialty: ''}])
                }
                
                setAffiliationValues(response.data.affiliations)
                setChamberValues(response.data.chambers)
                setConsultationTimeValues(response.data.appointment_schedules)
                setRegularFee(response.data.fee)
                setFollowUpFee(response.data.followup_fee)
                setFollowUpDuration(response.data.followup_duration)
                setAccountTypeVal(response.data.account_type)
                setAccountName(response.data.account_name ? response.data.account_name : '')
                setAccountNumber(response.data.account_number ? response.data.account_number : '')
                setBankName(response.data.bank_name ? response.data.bank_name : '')
                setBranchName(response.data.branch_name ? response.data.branch_name : '')
                setRelToAccHolder(response.data.relation_to_acc_holder ? response.data.relation_to_acc_holder : '')
                setMobileBankingNumber(response.data.mobile_banking_number ? response.data.mobile_banking_number : '')
                setOperator(response.data.mobile_banking_operator ? response.data.mobile_banking_operator : '')
                setAltMobileBankingNumber(response.data.alt_mobile_banking_number ? response.data.alt_mobile_banking_number : '')
                setAltOperator(response.data.alt_mobile_banking_operator ? response.data.alt_mobile_banking_operator : '')
            } else {
                console.log('PROFILE FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            closeSnackbar();
            console.log('PFOFILE FETCH ERROR', error);
        })
    }

    const setContactPrefVal = (val) => {
        if (val === 'Personal') {
            setContactPreference({...contactPreference, personal: true});
        }
        else if (val === 'Assistant') {
            setContactPreference({...contactPreference, assistant: true});
        }
    } 

    const getContactPrefVal = () => {
        if (contactPreference.personal) {
            return 'Personal';
        }
        else if (contactPreference.assistant) {
            return 'Assistant';
        }
        else {
            return '';
        }
    }

    const setAccountTypeVal = (val) => {
        if (val === 'Savings') {
            setAccountType({...accountType, savings: true});
        }
        else if (val === 'Current') {
            setAccountType({...accountType, current: true});
        }
    }

    const getAccountTypeVal = () => {
        if (accountType.savings) {
            return 'Savings';
        }
        else if (accountType.current) {
            return 'Current';
        }
        else {
            return '';
        }
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog fullScreen scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="pt-1 pb-1" style={{ backgroundColor: colors.lightGrey }}>
                <HBox align="center">
                    <H3 className='ml-2'>Doctor Profile</H3>
                    <P1 className="ml-1">for <span style={{ color: colors.red }}>{doctorName}</span></P1>
                </HBox>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <Container>
            <LeftPanel style={{ height: 'calc(100vh - 50px)' }}/>
            <CenterPanel>
                <Scrollbars
                    style={{ height: 'calc(100vh - 50px)' }}
                    renderThumbVertical={({ style, ...props }) =>
                        <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '5px', borderRadius: '3px', opacity: '0.4' }} />
                    }
                >
                    <VBox className="p-3" style={{ width: '100%' }}>
                        <H5 className='ml-3'>(*) marked fields are mandatory</H5>
                        {showError ? <H5 className='ml-3 mt-2' style={{ color: colors.red }}>{errorMessage}</H5> : ''}
                        <Paper className='p-3'>
                            <H3>Personal Information</H3>
                            
                            <HBox align='center' className='mt-2 ml-1'>
                               <H5 style={{ fontWeight: 'normal'}}>{prevImage ? 'Update' : 'Add'} Photo</H5> <AddPhoto onClick={() => setUploadPicOpen(true)}/>
                               {image ? <H6 className='ml-1' color='third'>File Added</H6> : null}
                               {prevImage && !image ? <H6 className='ml-1'>{prevImage}</H6> : null}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                               <H5 style={{ fontWeight: 'normal'}}>{prevSign ? 'Update' : 'Add'} Signature</H5> <AddPhoto onClick={() => setUploadSignOpen(true)}/>
                               {sign ? <H6 className='ml-1' color='third'>File Added</H6> : null}
                               {prevSign && !sign ? <H6 className='ml-1'>{prevSign}</H6> : null}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    value={doctorType}
                                    label='Doctor Type *' 
                                    list='doctor-type'
                                    style={{width: '50%'}}
                                    onChange={(event) => handleChangeDoctorType(event)}
                                />
                                
                                <datalist id='doctor-type'>
                                    <option key='MBBS'>MBBS</option>
                                    <option key='BDS'>BDS</option>
                                </datalist>
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    value={title}
                                    label='Title *'
                                    list='title'
                                    style={{width: '100%'}}
                                    onChange={(event) => handleChangeTitle(event)}
                                />

                                <datalist id='title'>
                                    {doctorTitleData.map((title) => (
                                        <option>{title}</option>
                                    ))}
                                </datalist>
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    // disabled
                                    value={firstName}
                                    label='First Name *' 
                                    style={{width: '100%'}}
                                    onChange={(event) => handleChangeFirstName(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText
                                    // disabled 
                                    value={lastName}
                                    label='Last Name *' 
                                    style={{width: '100%'}}
                                    onChange={(event) => handleChangeLastName(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    value={banglaTitle}
                                    label='Title (Bangla)'
                                    list='title-bangla'
                                    style={{width: '100%'}}
                                    onChange={(event) => handleChangeBanglaTitle(event)}
                                />

                                <datalist id='title-bangla'>
                                    {doctorBanglaTitleData.map((title) => (
                                        <option>{title}</option>
                                    ))}
                                </datalist>
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    value={fullNameBangla}
                                    label='Full Name (Bangla)' 
                                    style={{width: '100%'}}
                                    onChange={(event) => handleChangeFullNameBangla(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    list='gender'
                                    label='Gender' 
                                    style={{width: '100%'}}
                                    value={gender}
                                    onChange={(event) => handleChangeGender(event)}
                                />

                                 <datalist id='gender'>
                                    <option key='MBBS'>Male</option>
                                    <option key='BDS'>Female</option>
                                    <option key='MBBS'>Other</option>
                                </datalist>
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    value={address}
                                    label='Address' 
                                    style={{width: '100%'}}
                                    onChange={(event) => handleChangeAddress(event)}
                                />
                            </HBox>
                        </Paper>
                        <Paper className='p-3'>
                            <H3>Contact Information</H3>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    value={phone}
                                    label='Phone *' 
                                    style={{width: '100%'}}
                                    onChange={(event) => handleChangePhone(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label="Assistant's Phone" 
                                    style={{width: '100%'}}
                                    value={assistantPhone}
                                    onChange={(event) => handleChangeAssistantPhone(event)}
                                />
                            </HBox>
                            <HBox className='mt-2 ml-1'>
                                <P2>Contact Preference</P2>
                                <HBox className='ml-2'>
                                    <input 
                                        className='mr-1' 
                                        type='checkbox' 
                                        name='personal'
                                        checked={contactPreference.personal}
                                        onChange={(event) => handleChangeContactPref(event)}
                                    /> Personal
                                </HBox>
                                <HBox className='ml-2'>
                                    <input 
                                        className='mr-1' 
                                        type='checkbox'
                                        name='assistant' 
                                        checked={contactPreference.assistant}
                                        onChange={(event) => handleChangeContactPref(event)}
                                    /> Assistant
                                </HBox>
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    value={email}
                                    label='Email' 
                                    style={{width: '100%'}}
                                    onChange={(event) => handleChangeEmail(event)}
                                />
                            </HBox>
                        </Paper>
                        <Paper className='p-3'>
                            <H3>Professional Information</H3>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    value={bmdc}
                                    // type='number'
                                    label='BMDC Reg. No. *' 
                                    style={{width: '100%'}}
                                    onChange={(event) => handleChangeBmdc(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    value={qualification}
                                    label='Academic Qualification' 
                                    style={{width: '100%'}}
                                    onChange={(event) => handleChangeQualification(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    value={trainings}
                                    label='Post-Grad Trainings' 
                                    style={{width: '100%'}}
                                    onChange={(event) => handleChangeTrainings(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    value={experience}
                                    label='Years of Experience' 
                                    style={{width: '100%'}}
                                    onChange={(event) => handleChangeExperience(event)}
                                />
                            </HBox>
                            <VBox>
                                <SpecialtiesHtml 
                                    specialtyValues={specialtyValues}
                                    setSpecialtyValues={setSpecialtyValues}
                                />
                            </VBox>
                        </Paper>
                        <VBox>
                            <AffiliationsHtml 
                                affiliationValues={affiliationValues}
                                setAffiliationValues={setAffiliationValues}
                            />
                        </VBox>
                        <Paper className='p-3'>
                            <HBox align='center'>
                                <H3>Chambers</H3>
                                <IoMdAddCircle
                                    style={{ fontSize: '24px', margin: '6px', color: colors.green, cursor: 'pointer'}}
                                    onClick={() => setChamberValues([...chamberValues, {address: ''}])}
                                />
                            </HBox>
                            <VBox>
                                <ChambersHtml
                                    chamberValues={chamberValues}
                                    setChamberValues={setChamberValues}
                                />
                            </VBox>
                        </Paper>
                        <VBox>
                            <ConsultationTimeHtml 
                                consultationTimeValues={consultationTimeValues}
                                setConsultationTimeValues={setConsultationTimeValues}
                            />
                        </VBox>
                        <Paper className='p-3'>
                            <H3>Fees</H3>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    value={regularFee}
                                    label='Regular Fee *'
                                    type='number'
                                    style={{width: '100%'}}
                                    onChange={(event) => handleChangeRegularFee(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    value={followUpFee}
                                    label='Follow Up Fee *'
                                    type='number'
                                    style={{width: '100%'}}
                                    onChange={(event) => handleChangeFollowupFee(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    value={followUpDuration}
                                    label='Follow Up Duration *'
                                    type='number'
                                    style={{width: '100%'}}
                                    onChange={(event) => handleChangeFollowupDuration(event)}
                                />
                            </HBox>
                        </Paper>
                        <Paper className='p-3'>
                            <H3>Bank Information</H3>
                            <HBox className='mt-2 ml-1'>
                                <P2>Account Type</P2>
                                <HBox className='ml-2'>
                                    <input 
                                        className='mr-1' 
                                        type='checkbox' 
                                        name='savings'
                                        checked={accountType.savings}
                                        onChange={(event) => handleChangeAccountType(event)}
                                    /> Savings
                                </HBox>
                                <HBox className='ml-2'>
                                    <input 
                                        className='mr-1' 
                                        type='checkbox'
                                        name='current' 
                                        checked={accountType.current}
                                        onChange={(event) => handleChangeAccountType(event)}
                                    /> Current
                                </HBox>
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    value={accountName}
                                    label='A/C Name' 
                                    style={{width: '100%'}}
                                    onChange={(event) => handleChangeAccountName(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    value={accountNumber}
                                    label='A/C Number' 
                                    style={{width: '100%'}}
                                    onChange={(event) => handleChangeAccountNumber(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    value={bankName}
                                    label='Name of Bank' 
                                    style={{width: '100%'}}
                                    onChange={(event) => handleChangeBankName(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    value={branchName}
                                    label='Branch' 
                                    style={{width: '100%'}}
                                    onChange={(event) => handleChangeBranchName(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='Relation to A/C Holder (if not personal)' 
                                    style={{width: '100%'}}
                                    value={relToAccHolder}
                                    onChange={(event) => handleChangeRelToAccHolder(event)}
                                />
                            </HBox>
                        </Paper>
                        <Paper className='p-3'>
                            <H3>Mobile Banking Information</H3>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    value={mobileBankingNumber}
                                    label='Mobile A/C Number' 
                                    style={{width: '100%'}}
                                    onChange={(event) => handleChangeMobileBankingNumber(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    value={operator}
                                    label='Operator'
                                    list='operator'
                                    style={{width: '100%'}}
                                    onChange={(event) => handleChangeOperator(event)}
                                />

                                <datalist id='operator'>
                                    {mobileBankingOperatorData.map((op) => (
                                        <option>{op}</option>
                                    ))}
                                </datalist>
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='Mobile A/C Number (Alternative)' 
                                    style={{width: '100%'}}
                                    value={altMobileBankingNumber}
                                    onChange={(event) => handleChangeAltMobileBankingNumber(event)}
                                />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <InputText 
                                    label='Operator (Alternative)'
                                    list='operator-alt' 
                                    style={{width: '100%'}}
                                    value={altOperator}
                                    onChange={(event) => handleChangeAltOperator(event)}
                                />

                                <datalist id='operator-alt'>
                                    {mobileBankingOperatorData.map((op) => (
                                        <option>{op}</option>
                                    ))}
                                </datalist>
                            </HBox>
                        </Paper>
                        <HBox justify='center' className='mt-2 ml-1'>
                            <Button 
                                color='third'
                                onClick={() => submitForm('Draft')}
                            >
                                Update
                            </Button>
                            <Button 
                                className='ml-2'
                                color='second'
                                onClick={() => submitForm('On Hold')}
                            >
                                Send Onboard Request
                            </Button>
                        </HBox>
                    </VBox>
                    
                </Scrollbars>
                <PictureUploadDlg
                    open={uploadPicOpen}
                    setOpen={setUploadPicOpen}
                    handleAddPhoto={handleAddPhoto}
                />
                <PictureUploadDlg
                    open={uploadSignOpen}
                    setOpen={setUploadSignOpen}
                    handleAddPhoto={handleAddSign}
                    aspect={3.75}
                    title='Update Signature'
                />
            </CenterPanel>
            <RightPanel style={{ height: 'calc(100vh - 50px)' }} />
            
        </Container>
        </Dialog>
    )
}

export default SetProfileDlg;