import { FiSquare, FiCheckSquare } from "react-icons/fi";
import { IoSquareOutline, IoCheckbox } from "react-icons/io5";

import { P1 } from "./Typography";
import { HBox, VBox } from "./Containers";
import colors from "../config/colors";

const CheckChoice = ({ items, setItems, className, style, justify, radio=false, direction='column' }) => {
    
    const handleClick = (index) => {
        if (radio) {
            var values = [...items];
            for (var i=0; i<values.length; i++) {
                 values[i].checked = false;
            }
            values[index].checked = true;
            
            setItems(values);
        }
        else {
            var values = [...items];
            values[index].checked = !values[index].checked;
            
            setItems(values);
        }
    }

    return (
        <>
            {
                direction === 'column' ?
                <VBox className={className}>
                    {
                        items.map((item, index) => (
                            <HBox align="center" className='p-0_5 clickable' onClick={() => handleClick(index)}>
                                {item.checked ?
                                    <IoCheckbox style={{ color: colors.green, fontSize: 24 }} />
                                    :
                                    <IoSquareOutline style={{ fontSize: 24 }} />
                                }
                                <P1 className="ml-1">{item.text}</P1>
                            </HBox>
                        ))
                    }
                </VBox>
                :
                <HBox className={className} style={style} justify={justify}>
                    {
                        items.map((item, index) => (
                            <HBox className='p-0_5 clickable' onClick={() => handleClick(index)}>
                                {item.checked ?
                                    <IoCheckbox style={{ color: colors.green, fontSize: 24 }} />
                                    :
                                    <IoSquareOutline style={{ fontSize: 24 }} />
                                }
                                <P1 className="ml-1">{item.text}</P1>
                            </HBox>
                        ))
                    }
                </HBox>
            }
        </>
    )
}

export default CheckChoice;