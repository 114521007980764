import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FiEdit3, FiCheck, FiX, FiPlusSquare, FiTrash } from "react-icons/fi";
import axios from "axios";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import { useSnackbar } from "notistack";
import { Dialog, Tooltip } from "@mui/material";
import { FaCamera } from "react-icons/fa";

import { IconButton } from "../../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import { VBox, HBox, LeftPanel, CenterPanel, RightPanel } from "../../../components/Containers";
import { getTime } from "../../../utils";
import colors from "../../../config/colors";
import responsive from "../../../config/responsive";
import noImage from '../../../assets/images/noImage.png';

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'


const Container = styled(HBox)``;

const Image = styled.img`
    height: 120px;
    width: 120px;
    border-radius: 60px;
    border: 1px solid ${colors.grey};
`

const Signature = styled.img`
    height: 32px;
    width: 120px;
    border: 1px solid ${colors.grey};
`

const AddPhoto = styled(FaCamera)`
    color: ${colors.red};
    font-size: 25px;
    cursor: pointer;
    margin-left: 8px;
    :hover {
        color: ${colors.darkRed};
    }
`

const TopPanel = styled(HBox)`
    width: 100%;
    background-color: ${colors.lightGrey};
    border-bottom: 1px solid ${colors.grey};
`

const Paper = styled.div`
    width: 100%;
    border-radius: 10px;
`

const Input = styled.input`
    border: 1px solid ${colors.grey};
    color: ${colors.darkGrey};
    border: none;
    border-bottom: 1px solid ${colors.grey};
    
    :focus {
        color: black;
        outline: none;
    }
`

const Select = styled.select`
    color: ${colors.darkGrey};
    border: none;
    border-bottom: 1px solid ${colors.grey};

    :focus {
        color: black;
        outline: none;
    }
`

const EditIcon = styled(FiEdit3)`
    color: ${colors.blue};
    cursor: pointer;
`

const CheckIcon = styled(FiCheck)`
    color: ${colors.green};
    cursor: pointer;
`

const XIcon = styled(FiX)`
    color: ${colors.red};
    cursor: pointer;
`

const AddIcon = styled(FiPlusSquare)`
    color: ${colors.blue};
    cursor: pointer;
`

const DeleteIcon = styled(FiTrash)`
    color: ${colors.red};
    cursor: pointer;
`

const SLink = styled(Link)`
    text-decoration: none;
`

const HighlightChip = styled(HBox)`
    background-color: ${colors.green};
    border: 1px solid ${colors.green};
    border-radius: 2px;
`

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const getDaysStr = (schedule) => {
    let days = [];
    if (schedule?.saturday) days.push('Sat');
    if (schedule?.sunday) days.push('Sun');
    if (schedule?.monday) days.push('Mon');
    if (schedule?.tuesday) days.push('Tue');
    if (schedule?.wednesday) days.push('Wed');
    if (schedule?.thursday) days.push('Thu');
    if (schedule?.friday) days.push('Fri');

    return (
        <>
        {days.map((day, index) => (
            index === 0 ?
                <span>{day}</span>
                :
                <span>{',' + day}</span>
        ))}
        </>
    )
}

const NotSetHtml = () => {
    return (
        <span style={{ color: colors.red, fontWeight: 'bold' }}>Not set</span>
    )
}

const SpecialtiesHtml = ({ specialtyValues, setSpecialtyValues }) => {
    return (
        <>
        <HBox className='mt-2 ml-1'>
            <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Specialty: </H5> 
            <VBox className='ml-3'>
                {
                    specialtyValues.map((specialty, index) => (
                        <ul>
                            <li key={index}><P2>{specialty.specialty ? specialty.specialty : <span style={{ color: colors.red, fontWeight: 'bold' }}>Not set</span>}</P2></li>
                        </ul>
                    ))
                }     
            </VBox>       
        </HBox>
        </>
    )
}

const AffiliationsHtml = ({ affiliationValues, setAffiliationValues }) => {
    return (
        <>  
            <HBox className='mt-2 ml-4'>
                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Affiliations: </H5>
                <VBox className='ml-3'>
                <ol>
                {
                    affiliationValues.map((affiliation, index) => (
                            <li key={index}>
                                <H5 style={{ fontWeight: 'normal'}}>Institution/Hospital: {affiliation.institution ? affiliation.institution : <NotSetHtml />}</H5>
                                <H5 style={{ fontWeight: 'normal'}}>Designation: {affiliation.designation ? affiliation.designation : <NotSetHtml />}</H5>
                                <H5 style={{ fontWeight: 'normal'}}>Department: {affiliation.department ? affiliation.department : <NotSetHtml />}</H5>
                                <H5 style={{ fontWeight: 'normal'}}>Currently working: {affiliation.currently_working ? 'Yes' : 'No'}</H5>
                                <H5 style={{ fontWeight: 'normal'}}>Highlighted in profiile: {affiliation.show_in_summary ? 'Yes' : 'No'}</H5>
                            </li>
                        
                    ))
                }
                </ol>
                </VBox>
            </HBox>
        </>
    )
}

const ChambersHtml = ({ chamberValues, setChamberValues }) => {
    return (
        <>
            <HBox className='mt-2 ml-1'>
            <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Chambers: </H5>
                <VBox className='ml-3'>
                    <ul>
                    {
                        chamberValues.map((chamber, index) => (
                            <li><P2>{chamber.address ? chamber.address : <NotSetHtml />}</P2></li>
                        ))
                    }
                    </ul>
                </VBox>
            </HBox>
        </>
    )
}

const ConsultationTimeHtml = ({ consultationTimeValues, setConsultationTimeValues}) => {
    return (
        <>
            <HBox className='mt-2 ml-4'>
            <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Consultation Times: </H5>
                <VBox className='ml-3'>
                    <ol>
                    {
                        consultationTimeValues.map((consultationTime, index) => (
                           <li>
                                <H5 style={{ fontWeight: "normal"}}>
                                    Time: {consultationTime.schedule?.startTime ? getTime(consultationTime.schedule?.startTime) : <span style={{ color: colors.red, fontWeight: 'bold' }}>Not set</span>}
                                    -
                                    {consultationTime.schedule?.endTime ? getTime(consultationTime.schedule?.endTime) : <NotSetHtml />}</H5>
                                <H5 style={{ fontWeight: "normal"}}>Days: {getDaysStr(consultationTime.schedule) ? getDaysStr(consultationTime.schedule) : <NotSetHtml />}
                                </H5>
                           </li> 
                        ))
                    }
                    </ol>
                </VBox>
            </HBox>
        </>
    )
}

const ViewDoctorDetailsDlg = ({ open, setOpen, doctorId, doctorName }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [image, setImage] = useState('');
    const [sign, setSign] = useState('');
    const [title, setTitle] = useState('');
    const [doctorType, setDoctorType] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [banglaTitle, setBanglaTitle] = useState('');
    const [fullNameBangla, setFullNameBangla] = useState('');
    const [gender, setGender] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [assistantPhone, setAssistantPhone] = useState('');
    const [contactPreference, setContactPreference] = useState('');
    const [email, setEmail] = useState('');
    const [bmdc, setBmdc] = useState('');
    const [qualification, setQualification] = useState('');
    const [trainings, setTrainings] = useState('');
    const [experience, setExperience] = useState('');
    const [specialtyValues, setSpecialtyValues] = useState([{specialty: ''}]);
    const [affiliationValues, setAffiliationValues] = useState([{institution: '', designation: '', department: '', currently_working: false, show_in_summary: false}]);
    const [chamberValues, setChamberValues] = useState([{address: ''}]);
    const [consultationTimeValues, setConsultationTimeValues] = useState([{id: null, schedule: {
        startTime: '',
        endTime: '',
        friday: false,
        saturday: false,
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false
    }}]);
    const [regularFee, setRegularFee] = useState('');
    const [followUpFee, setFollowUpFee] = useState('');
    const [followUpDuration, setFollowUpDuration] = useState('');
    const [accountType, setAccountType] = useState('');
    const [accountName, setAccountName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [bankName, setBankName] = useState('');
    const [branchName, setBranchName] = useState('');
    const [relToAccHolder, setRelToAccHolder] = useState('');
    const [mobileBankingNumber, setMobileBankingNumber] = useState('');
    const [operator, setOperator] = useState('');
    const [altMobileBankingNumber, setAltMobileBankingNumber] = useState('');
    const [altOperator, setAltOperator] = useState(''); 
    const [createdAt, setCreatedAt] = useState('');
    const [editedAt, setEditedAt] = useState('');

    const [isMobile, setIsMobile] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    useEffect(() => {
        if (open) {
            enqueueSnackbar("Loading...", { persist: true });
            getData();
        }
    }, [open, doctorId]);

    const getData = async () => {
        setIsLoading(true);
        await axios({
            method: 'GET',
            url: `moderator/doctor-detail/`,
            params: {
                doctor_id: doctorId,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            setIsLoading(false);
            closeSnackbar();
            if (response.status === 200) {
                setDoctorType(response.data.doctor_type)
                setImage(response.data.image)
                setSign(response.data.sign)
                setTitle(response.data.title)
                setFirstName(response.data.first_name)
                setLastName(response.data.last_name)
                setBanglaTitle(response.data.bangla_title)
                setFullNameBangla(response.data.bangla_name)
                setGender(response.data.gender)
                setAddress(response.data.address)
                setPhone(response.data.phone)
                setAssistantPhone(response.data.assistant_phone)
                setContactPreference(response.data.contact_preference)
                setEmail(response.data.email)
                setBmdc(response.data.bmdc)
                setQualification(response.data.qualification)
                setTrainings(response.data.trainings)
                setExperience(response.data.experience)
                if (Array.isArray(response.data.specialty) && response.data.specialty.length > 0) {
                    setSpecialtyValues(
                        response.data.specialty.map(value => ({['specialty']: value}))
                    )
                } else if (response.data.specialty.length > 0) {
                    setSpecialtyValues([{specialty: response.data.specialty}])
                } else {
                    setSpecialtyValues([{specialty: ''}])
                }
                setAffiliationValues(response.data.affiliations)
                setChamberValues(response.data.chambers)
                setConsultationTimeValues(response.data.appointment_schedules)
                setRegularFee(response.data.fee)
                setFollowUpFee(response.data.followup_fee)
                setFollowUpDuration(response.data.followup_duration)
                setAccountType(response.data.account_type)
                setAccountName(response.data.account_name)
                setAccountNumber(response.data.account_number)
                setBankName(response.data.bank_name)
                setBranchName(response.data.branch_name)
                setRelToAccHolder(response.data.relation_to_acc_holder)
                setMobileBankingNumber(response.data.mobile_banking_number)
                setOperator(response.data.mobile_banking_operator)
                setAltMobileBankingNumber(response.data.alt_mobile_banking_number)
                setAltOperator(response.data.alt_mobile_banking_operator)
                setCreatedAt(response.data.created_at)
                setEditedAt(response.data.edited_at)
            } else {
                console.log('PROFILE FETCH FAILED', response.status);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            closeSnackbar();
            console.log('PFOFILE FETCH ERROR', error);
        })
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog fullScreen scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="pt-1 pb-1" style={{ backgroundColor: colors.lightGrey }}>
                <HBox align="center">
                    <H3 className='ml-2'>Details</H3>
                    <P1 className="ml-1">of <span style={{ color: colors.red }}>{doctorName}</span></P1>
                </HBox>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <Container>
            <LeftPanel style={{ height: 'calc(100vh - 50px)' }}/>
            <CenterPanel>
                <Scrollbars
                    style={{ height: 'calc(100vh - 50px)' }}
                    renderThumbVertical={({ style, ...props }) =>
                        <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '5px', borderRadius: '3px', opacity: '0.4' }} />
                    }
                >
                    <VBox className="p-3" style={{ width: '100%' }}>
                        <Paper className='p-3'>
                            <H3>Personal Information</H3>
                            
                            <HBox align='center' className='mt-2 ml-1'>
                               <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Photo: </H5> 
                               <Image 
                                    src={image ? `${baseURL}${image}` : noImage}
                                    
                               />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                               <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Signature: </H5> 
                               <Signature 
                                    src={sign ? `${baseURL}${sign}` : noImage}
                               />
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Doctor Type: </H5> {doctorType ? doctorType : <NotSetHtml />}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Title: </H5> {title ? title : <NotSetHtml />}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>First Name: </H5> {firstName ? firstName : <NotSetHtml />}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Last Name: </H5> {lastName ? lastName : <NotSetHtml />}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Title (Bangle): </H5> {banglaTitle ? banglaTitle : <NotSetHtml />}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Full Name (Bangla): </H5> {fullNameBangla ? fullNameBangla : <NotSetHtml />}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Gender: </H5> {gender ? gender : <NotSetHtml />}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Address: </H5> {address ? address : <NotSetHtml />}
                            </HBox>
                        </Paper>
                        <Paper className='p-3'>
                            <H3>Contact Information</H3>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Phone Number: </H5> {phone ? phone : <NotSetHtml />}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Assistant's Phone Number: </H5> {assistantPhone ? assistantPhone : <NotSetHtml />}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Contact Preference: </H5> {contactPreference ? contactPreference : <NotSetHtml />}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Email: </H5> {email ? email : <NotSetHtml />}
                            </HBox>
                        </Paper>
                        <Paper className='p-3'>
                            <H3>Professional Information</H3>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>BMDC Reg. No.: </H5> {bmdc ? bmdc : <NotSetHtml />}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Academic Qualification: </H5> {qualification ? qualification : <NotSetHtml />}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Post-Grad Trainings: </H5> {trainings ? trainings : <NotSetHtml />}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Years of Experience: </H5> {experience ? experience : <NotSetHtml />}
                            </HBox>
                            <VBox>
                                <SpecialtiesHtml 
                                    specialtyValues={specialtyValues}
                                    setSpecialtyValues={setSpecialtyValues}
                                />
                            </VBox>
                        </Paper>
                        <VBox>
                            <AffiliationsHtml 
                                affiliationValues={affiliationValues}
                                setAffiliationValues={setAffiliationValues}
                            />
                        </VBox>
                        <Paper className='p-3'>
                            <VBox>
                                <ChambersHtml
                                    chamberValues={chamberValues}
                                    setChamberValues={setChamberValues}
                                />
                            </VBox>
                        </Paper>
                        <VBox>
                            <ConsultationTimeHtml 
                                consultationTimeValues={consultationTimeValues}
                                setConsultationTimeValues={setConsultationTimeValues}
                            />
                        </VBox>
                        <Paper className='p-3'>
                            <H3>Fees</H3>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Regular Fee: </H5> ৳ {regularFee ? regularFee : <NotSetHtml />}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Follow Up Fee: </H5> ৳ {followUpFee ? followUpFee : <NotSetHtml />}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Follow Up Duration: </H5>{followUpDuration ? followUpDuration : <NotSetHtml />} Days
                            </HBox>
                        </Paper>
                        <Paper className='p-3'>
                            <H3>Bank Information</H3>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>A/C Type: </H5> {accountType ? accountType : <NotSetHtml />}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>A/C Name: </H5> {accountName ? accountName : <NotSetHtml />}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>A/C Number: </H5> {accountNumber ? accountNumber : <NotSetHtml />}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Name of Bank: </H5> {bankName ? bankName : <NotSetHtml />}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Branch: </H5> {branchName ? branchName : <NotSetHtml />}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Relation to A/C Holder (if not personal): </H5> {relToAccHolder ? relToAccHolder : <NotSetHtml />}
                            </HBox>
                        </Paper>
                        <Paper className='p-3'>
                            <H3>Mobile Banking Information</H3>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Mobile A/C Number: </H5> {mobileBankingNumber ? mobileBankingNumber : <NotSetHtml />}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Operator: </H5> {operator ? operator : <NotSetHtml />}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Mobile A/C Number (Alternative): </H5> {altMobileBankingNumber ? altMobileBankingNumber : <NotSetHtml />}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Operator (Alternative): </H5> {altOperator ? altOperator : <NotSetHtml />}
                            </HBox>
                        </Paper>
                        <Paper className='p-3'>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Added At: </H5> {moment(createdAt).format('LLL')}
                            </HBox>
                            <HBox align='center' className='mt-2 ml-1'>
                                <H5 style={{ fontWeight: 'bold', marginRight: 4}}>Edited At: </H5> {moment(editedAt).format('LLL')}
                            </HBox>
                        </Paper>
                    </VBox>
                    
                </Scrollbars>
            </CenterPanel>
            <RightPanel style={{ height: 'calc(100vh - 50px)' }} />
            
        </Container>
        </Dialog>
    )
}

export default ViewDoctorDetailsDlg;
