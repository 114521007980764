import { useState, useEffect, useRef } from 'react';
import styled from "styled-components";
import axios from "axios";
import { useSnackbar } from 'notistack';
import { BiImport } from "react-icons/bi";
import { Scrollbars } from 'react-custom-scrollbars';

import { HBox, VBox } from "../../components/Containers";
import { Button } from "../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../components/Typography";
import colors from "../../config/colors";
import { LowerNav } from "../../layouts/Navbar";
import Footer from "../../layouts/Footer";
import DoctorTable from "./components/DoctorTable";

const LeftPanel = styled(VBox)`
    width: 20%;
    height: calc(100vh - 150px);
    overflow-y: auto;
    border-right: 1px solid ${colors.grey};
`

const Container = styled(VBox)`
    width: 100%;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
`

const Doctors = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const inputRef = useRef(null);

    const handleClickBrowse = event => {
        inputRef.current.click();
    };

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            handleAddFile(e.target.files[0]);
            e.target.value = null;
        }
    };

    const handleAddFile = (uploadFile) => {
        const uploadData = new FormData();
        uploadData.append('file', uploadFile, uploadFile.name);

        enqueueSnackbar("Importing... Please wait a moment.", { persist: true });
        axios({
            method: "POST",
            url: 'nhadmin/import-drug-database/',
            data: uploadData,
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`,
            },
        }).then(function (res) {
            if (res.status === 201) {
                enqueueSnackbar('Successfully imported.', {variant: 'success'});
            }
            else {
                enqueueSnackbar('Import failure!', {variant: 'error'});
            }
        }).catch((error) => {
            enqueueSnackbar('Import error!', {variant: 'error'});
        });
    }

    return (
        <>
            <LowerNav selected='doctors' />
            <Container>
                <Scrollbars
                    style={{ height: 'calc(100vh - 50px)' }}
                    renderThumbVertical={({ style, ...props }) =>
                        <div {...props} style={{ ...style, backgroundColor: colors.darkGrey, width: '6px', borderRadius: '3px', opacity: '0.4'}}/>
                }>
                    <VBox className='p-4'>
                        <DoctorTable />
                    </VBox>
                </Scrollbars>
            </Container>
            <Footer />
        </>
    )
}

export default Doctors;