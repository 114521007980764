import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FiEdit3, FiCheck, FiX } from "react-icons/fi";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import { useSnackbar } from "notistack";
import { Dialog, Tooltip } from "@mui/material";

import { InputText } from "../../../components/InputText";
import { Button, NavBtnActive, NavBtn, IconButton } from "../../../components/Buttons";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3 } from "../../../components/Typography";
import { VBox, HBox, LeftPanel, CenterPanel, RightPanel } from "../../../components/Containers";
import Confirm from "../../../layouts/Confirm";
import colors from "../../../config/colors";
import responsive from "../../../config/responsive";
import { getTime, getDate } from "../../../utils";

const TopPanel = styled(HBox)`
    width: 100%;
    background-color: ${colors.lightGrey};
    border-bottom: 1px solid ${colors.grey};
`

const Paper = styled.div`
    width: 100%;
    border: 1px solid ${colors.grey};
    border-radius: 10px;
`

const Input = styled.input`
    border: 1px solid ${colors.grey};
    color: ${colors.darkGrey};
    border: none;
    border-bottom: 1px solid ${colors.grey};
    
    :focus {
        color: black;
        outline: none;
    }
`

const EditIcon = styled(FiEdit3)`
    color: ${colors.blue};
    cursor: pointer;
`

const CheckIcon = styled(FiCheck)`
    color: ${colors.green};
    cursor: pointer;
`

const XIcon = styled(FiX)`
    color: ${colors.red};
    cursor: pointer;
`

const SLink = styled(Link)`
    text-decoration: none;
`

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
`

const ConfirmEditRequestDlg = ({ open, setOpen, doctorId, doctorName, doctors, setDoctors, doctorIndex }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [appConfig, setAppConfig] = useState({
        fee: null, followup_fee: null, urgent_fee: null,
    });
    const [editData, setEditData] = useState({
        fee: null, followup_fee: null, urgent_fee: null,
    });
    const [editState, setEditState] = useState({
        fee: false, followup_fee: false, urgent_fee: false,
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            let orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
            
            if (orientation === 'portrait' || window.innerWidth < responsive.mobileThresh) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => window.removeEventListener('resize', () => setResponsiveness());
    }, []);

    const confirmEditRequest = () => {
        enqueueSnackbar("Submitting...", { persist: true });
        axios({
            method: 'PUT',
            url: `moderator/manage-doctor/`,
            data: {
                doctor_id: doctorId,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('nh-access')}`
            }
        })
        .then((response) => {
            closeSnackbar();
            if (response.status === 200) {
                let values = [...doctors]
                values[doctorIndex].is_edit_requested = response.data.is_edit_requested
                setDoctors(values)
                setOpen(false)
                enqueueSnackbar('Edit request submitted.', {variant: 'success'});
            } else {
                console.log('EDIT REQUEST FAILED', response.status);

                enqueueSnackbar('Something went wrong. Please try again.', {variant: 'error'});
            }
        })
        .catch((error) => {
            closeSnackbar();
            console.log('EDIT REQUEST ERROR', error);

            enqueueSnackbar('Something went wrong. Please try again.', {variant: 'error'});
        })
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog  scroll='body' open={open} onClose={handleClose} fullWidth>
            <HBox align="center" justify='space-between' className="pt-1 pb-1" style={{ backgroundColor: colors.lightGrey }}>
                <HBox align="center">
                    <H3 className='ml-2'>Request Edit</H3>
                    <P1 className="ml-1">for <span style={{ color: colors.red }}>{doctorName}</span></P1>
                </HBox>
                <IconButton
                    className="mr-2"
                    onClick={handleClose}
                >
                    <FiX />
                </IconButton>
            </HBox>
            <Line />
            <VBox align='center' className="m-1">
                <H5>Are you sure to request edit for this doctor?</H5>
                <Button
                    className='m-2'
                    color='third'
                    onClick={() => confirmEditRequest()}
                >
                    Confirm
                </Button>
            </VBox>
        </Dialog>
    )
}

export default ConfirmEditRequestDlg;
